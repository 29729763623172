import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { capitalize } from '@/utils';

import { closeModal } from '../../../../localization/actions';
import { joinIngredientByName, joinText } from '../../../../common/string-formatter';
import {
  useRecipeDefaultCheese,
  useRecipeDefaultSauce,
  useRecipeDefaultFlavor,
  useRecipeDefaultToppings
} from '../pizzaIngredientOptionsSelectors';
import {
  cheeseOOSBody,
  sauceOOSBody,
  flavorOOSBody,
  toppingsOOSBody,
  multipleOOSBody
} from './constants';
import Routes from '../../../../router/routes';
import { IngredientOptionWithPortions, PizzaIngredientOption } from '../../dataTransformers/builderTypes';

type MultipleOOSOption = {
  outOfStock: boolean;
  value: (string | null)[];
};

const isAnyDefaultToppingOOS = (toppings: PizzaIngredientOption[]) => toppings
  .some((topping) => topping?.outOfStock);

const toppingsName = (toppings: PizzaIngredientOption[]) => toppings
  .filter((topping) => topping)
  .filter((topping) => topping.outOfStock)
  .map((topping) => topping.name || null);

const recipeIngredientOOSModalDetails = (
  recipeDefaultCheese: PizzaIngredientOption,
  recipeDefaultSauce: IngredientOptionWithPortions,
  recipeDefaultFlavor: Partial<PizzaIngredientOption>,
  recipeDefaultToppings: PizzaIngredientOption[]
) => {
  const toppingsOOS = isAnyDefaultToppingOOS(recipeDefaultToppings);
  const listOfToppings = toppingsName(recipeDefaultToppings);

  const multipleOOSOptions: MultipleOOSOption[] = [
    { outOfStock: toppingsOOS, value: listOfToppings || null },
    {
      outOfStock: recipeDefaultCheese?.outOfStock || false,
      value: recipeDefaultCheese?.portion ? [`${capitalize(recipeDefaultCheese?.portion)} Cheese`] : []
    },
    {
      outOfStock: recipeDefaultSauce?.outOfStock || false,
      value: [recipeDefaultSauce?.name && true ? recipeDefaultSauce.name : null]
    },
    {
      outOfStock: recipeDefaultFlavor.outOfStock as boolean,
      value: recipeDefaultFlavor.name ? [recipeDefaultFlavor.name] : []
    }
  ];

  const multipleOOSIngredients = multipleOOSOptions
    .map(({ outOfStock, value }) => (outOfStock ? value : undefined));

  switch (true) {
    case multipleOOSIngredients.filter(Boolean).length > 1:
      return {
        title: 'So good, they\'re gone',
        body: multipleOOSBody.replace('[ingredients]', joinIngredientByName(multipleOOSIngredients.flat())),
        ingredient: undefined
      };

    case toppingsOOS:
      return {
        title: 'Topping so good, it\'s gone',
        body: toppingsOOSBody.replace('[toppings]', joinText(listOfToppings)),
        ingredient: 'Topping'
      };

    case recipeDefaultCheese?.outOfStock:
      return {
        title: 'Cheese so good, it\'s gone',
        body: cheeseOOSBody,
        ingredient: 'Cheese'
      };

    case recipeDefaultFlavor?.outOfStock:
      return {
        title: 'Flavor so good, it\'s gone',
        body: recipeDefaultFlavor.name && flavorOOSBody.replace('[flavor]', recipeDefaultFlavor.name),
        ingredient: 'Flavor'
      };

    case recipeDefaultSauce?.outOfStock:
      return {
        title: 'Sauce so good, it\'s gone',
        body: recipeDefaultSauce.name && sauceOOSBody.replace('[sauce]', recipeDefaultSauce.name),
        ingredient: 'Sauce'
      };
    default:
      return undefined;
  }
};

const useOutOfStockModal = (): ModalContent | undefined => {
  const router = useRouter();
  const dispatch = useDispatch();
  const recipeDefaultCheese = useRecipeDefaultCheese();
  const recipeDefaultSauce = useRecipeDefaultSauce();
  const recipeDefaultFlavor = useRecipeDefaultFlavor();
  const recipeDefaultToppings = useRecipeDefaultToppings();

  const modalDetail = recipeIngredientOOSModalDetails(
    recipeDefaultCheese,
    recipeDefaultSauce,
    recipeDefaultFlavor,
    recipeDefaultToppings
  );

  if (!modalDetail) return undefined;

  const { ingredient, ...rest } = modalDetail;

  return {
    altCta: ingredient === 'Flavor' ? undefined : {
      text: 'CANCEL',
      reverseButtonsOrder: true,
      callback: () => router.push(Routes.MENU.PIZZA),
      ...(ingredient !== 'Cheese' && {
        text: 'Choose another recipe'
      })
    },
    cta: {
      text: ingredient === 'Flavor' ? 'OK' : 'CONTINUE',
      reverseButtonsOrder: true,
      callback: () => dispatch(closeModal())
    },
    ...rest
  };
};

export default useOutOfStockModal;
