import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { getGlobalId } from '@/cart/helpers/getGlobalId';
import { HIGH_WAGE_PRICING_DEALS, MID_WAGE_PRICING_DEALS } from './useDealRecipesPriceHardcoding';
import { PizzaIngredientOption } from '@/builders/pizza/dataTransformers/builderTypes';
import { selectors } from '@/builders/pizza/slice/pizza.slice';

const usePizzaSizePriceHardcoding = (sizes: PizzaIngredientOption[]): PizzaIngredientOption[] => {
  const [{ enabled: tavernDealPricingEnabled }] = useDecision('fr-dtg-706-new-tavern-deal-pricing', {
    autoUpdate: true
  });
  const deal = useSelector(dealSelectors.selectDeal);
  const pizzaDisplayName = useSelector(selectors.selectPizzaDisplayName);
  const dealPrice = deal?.data?.price;
  if (!tavernDealPricingEnabled) {
    return sizes;
  }
  const CYO_MID_WAGE_PRICING = {
    LARGE: 200,
    MEDIUM: 400
  };

  const RECIPE_MID_WAGE_PRICING = {
    LARGE: 0,
    MEDIUM: 200
  };

  const CYO_HIGH_WAGE_PRICING = {
    LARGE: 200,
    MEDIUM: 500
  };

  const RECIPE_HIGH_WAGE_PRICING = {
    LARGE: 0,
    MEDIUM: 300
  };

  const isCYO = pizzaDisplayName?.toLowerCase().includes('create');

  let updatedSizes = sizes;

  const getUpdatedSizeWithPrice = (size: PizzaIngredientOption, discountPrice: number): PizzaIngredientOption => ({
    ...size,
    price: dealPrice ? dealPrice - discountPrice : size.price
  });
  const updateHighWagePricing = (size: PizzaIngredientOption): PizzaIngredientOption => {
    if (isCYO) {
      const cyoDiscountedPrice = size.name?.toLowerCase().includes('large') ? CYO_HIGH_WAGE_PRICING.LARGE : CYO_HIGH_WAGE_PRICING.MEDIUM;
      return getUpdatedSizeWithPrice(size, cyoDiscountedPrice);
    }
    const recipeDiscountedPrice = size.name?.toLowerCase().includes('large') ? RECIPE_HIGH_WAGE_PRICING.LARGE : RECIPE_HIGH_WAGE_PRICING.MEDIUM;
    return getUpdatedSizeWithPrice(size, recipeDiscountedPrice);
  };

  const updateMidWagePricing = (size: PizzaIngredientOption): PizzaIngredientOption => {
    if (isCYO) {
      const cyoDiscountedPrice = size.name?.toLowerCase().includes('large') ? CYO_MID_WAGE_PRICING.LARGE : CYO_MID_WAGE_PRICING.MEDIUM;
      return getUpdatedSizeWithPrice(size, cyoDiscountedPrice);
    }
    const recipeDiscountedPrice = size.name?.toLowerCase().includes('large') ? RECIPE_MID_WAGE_PRICING.LARGE : RECIPE_MID_WAGE_PRICING.MEDIUM;
    return getUpdatedSizeWithPrice(size, recipeDiscountedPrice);
  };

  if (HIGH_WAGE_PRICING_DEALS.includes(getGlobalId(deal?.data?.id) ?? '')) {
    updatedSizes = sizes.map(updateHighWagePricing);
  } else if (MID_WAGE_PRICING_DEALS.includes(getGlobalId(deal?.data?.id) ?? '')) {
    updatedSizes = sizes.map(updateMidWagePricing);
  }

  return updatedSizes;
};

export default usePizzaSizePriceHardcoding;
