import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import { getGlobalId } from '@/cart/helpers/getGlobalId';

export const MID_WAGE_PRICING_DEALS = ['N70800x'];
export const HIGH_WAGE_PRICING_DEALS = ['N711000x', 'N711200x', 'N711300x'];
const useDealRecipesPriceHardcoding = (recipes?: DealRecipe[]): DealRecipe[] | undefined => {
  const [{ enabled: tavernDealPricingEnabled }] = useDecision('fr-dtg-706-new-tavern-deal-pricing', {
    autoUpdate: true
  });
  const deal = useSelector(dealSelectors.selectDeal);
  const dealPrice = deal?.data?.price;

  if (!tavernDealPricingEnabled) {
    return recipes;
  }

  let updatedRecipes = recipes;
  const updatePrice = (recipe: DealRecipe): DealRecipe => {
    if (dealPrice) {
      if (recipe.name.toLowerCase().includes('create')) {
        return {
          ...recipe,
          price: dealPrice ? dealPrice - 200 : recipe?.price
        };
      }
      return {
        ...recipe,
        price: dealPrice || recipe?.price
      };
    }
    return recipe; // Return the original recipe if not applicable
  };

  if (HIGH_WAGE_PRICING_DEALS.includes(getGlobalId(deal?.data.id) ?? '')
    || MID_WAGE_PRICING_DEALS.includes(getGlobalId(deal?.data.id) ?? '')) {
    updatedRecipes = recipes?.map(updatePrice);
  }

  return updatedRecipes;
};

export default useDealRecipesPriceHardcoding;
