import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import colors from '../../../../../../common/colors';
import LeftUnselected from '../../../../icons/PizzaToppings/LeftUnselected';
import RightUnselected from '../../../../icons/PizzaToppings/RightUnselected';
import BothUnselected from '../../../../icons/PizzaToppings/BothUnselected';
import Placement from '../../../../../../common/Placement';
import { extraSmallMobileStartBreakpoint } from '../../../../../../materialUi/theme';

const useStyles = () => makeStyles((theme: Theme) => ({
  radio: {
    padding: '13px',
    [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
      padding: '13px 8px'
    }
  }
}))();

interface ToppingPlacementProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label: string | undefined;
}

enum PizzaSplitOptionAriaLabel {
  LEFT = 'Left half only',
  BOTH = 'Whole pizza',
  RIGHT = 'Right half only'
}

const ToppingPlacement = ({ onChange, value, label }: ToppingPlacementProps): JSX.Element => {
  const classes = useStyles();

  return (
    <RadioGroup
      row
      onChange={onChange}
      value={value.toLowerCase()}
    >
      <Radio
        value={Placement.LEFT}
        checkedIcon={<LeftUnselected fillColor={colors.red} />}
        icon={<LeftUnselected />}
        className={classes.radio}
        inputProps={{ 'aria-label': PizzaSplitOptionAriaLabel.LEFT }}
        data-testid={`modifier-${label}-left`}
      />
      <Radio
        value={Placement.WHOLE}
        checkedIcon={<BothUnselected fillColor={colors.red} />}
        icon={<BothUnselected />}
        className={classes.radio}
        inputProps={{ 'aria-label': PizzaSplitOptionAriaLabel.BOTH }}
        data-testid={`modifier-${label}-whole`}
      />
      <Radio
        value={Placement.RIGHT}
        checkedIcon={<RightUnselected fillColor={colors.red} />}
        icon={<RightUnselected />}
        className={classes.radio}
        inputProps={{ 'aria-label': PizzaSplitOptionAriaLabel.RIGHT }}
        data-testid={`modifier-${label}-right`}
      />
    </RadioGroup>
  );
};

export default ToppingPlacement;
