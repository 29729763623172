import { ApolloError, QueryResult } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { dealDataOOSModalDetails } from '@/common/Modal/modalDetails';
import { DealData } from '@/builders/deals/slice/dealTypes';
import { onPizzaBuilderInitialLoadAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';
import { onGqlError } from '@/graphql/errors/actions';
import { QueryNames } from '@/graphql/errors/constants';
import { openModal } from '@/localization/actions';
import { NATIONAL, OccasionString } from '@/localization/constants';
import { RootState } from '@/rootStateTypes';
import useScrollTrackingForAnalytics from '../../../dataAnalytics/hooks/useScrollTrackingForAnalytics';
import { PizzaBuilderOptions } from '../../../graphql/dynamicQueries/pizza';
import Routes from '../../../router/routes';
import { Pizza } from '../dataTransformers/builderTypes';
import { pizzaOOSBody } from './useOutOfStockModal/constants';
import useDeepEffect from '@/common/useDeepEffect';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import { IdOrCode } from '@/../pages/menu/pizza/builder';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { useCCGetPizzaBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetPizzaBuilderQuery';
import { addNoSauce } from '@/clientCore/hardCodingHelpers/WEB-2491-yumPizzaNoSauce/helpers';

const ANALYTICS_THRESHOLD = 0.25;
const ANALYTICS_LABEL = 'Pizza Builder';

interface PizzaOptionsResult extends Partial<QueryResult> {
  pizzaOptions: PizzaBuilderOptions | null;
  loading: boolean;
}

export interface PizzaOptionsAnalytics extends Pizza {
  deal_id?: DealData['id'];
  deal_name?: DealData['name'];
}

const pizzaRecipeOOS = (data: undefined | PizzaBuilderOptions): boolean => !!data?.item?.outOfStock;

const pizzaIsNotAvailable = (data: undefined | PizzaBuilderOptions): boolean => !data?.item?.available;

const pizzaAvailableOnlyForOtherOccasion = (data: undefined | PizzaBuilderOptions): boolean => !!(!data?.item?.available && data?.item?.availableInOtherOccasion);

const gqlErrorMessage = (): ApolloError => {
  const errorMessage = 'Response not successful: Invalid data';
  return new ApolloError({ errorMessage });
};

export const useBuilderRef = (): ((node?: Element | null) => void) => {
  const pizza = useSelector((state: RootState): Pizza => state.domain.pizza);
  const deal = useSelector(dealSelectors.selectDealSummary, shallowEqual);
  const { isLineup } = useLineup();

  const pizzaAnalytics = (): PizzaOptionsAnalytics => ({
    ...pizza,
    deal_id: deal?.id,
    deal_name: deal?.name
  });
  return useScrollTrackingForAnalytics(
    onPizzaBuilderInitialLoadAnalytics,
    ANALYTICS_LABEL,
    pizzaAnalytics,
    ANALYTICS_THRESHOLD,
    false,
    isLineup
  );
};

const getOOSModalContent = (name: string | undefined) => ({
  title: "So good, it's gone",
  body: name && pizzaOOSBody.replace('[pizzaName]', name),
  cta: {
    text: 'OK'
  }
});

const getNotAvailableModalContent = () => ({
  title: "Sorry! The pizza you've clicked is not available at this store",
  body: 'Please take a look at our Pizza Menu',
  cta: { text: 'OK' }
});

const getAvailableForOtherOccasionModalContent = (occasion: OccasionString) => {
  const otherOccasion = {
    [OccasionString.C]: 'delivery',
    [OccasionString.D]: 'carryout'
  };

  return {
    title: `This pizza is for ${
      otherOccasion[
        occasion as keyof {
          Carryout: string;
          Delivery: string;
        }
      ]
    } only.`,
    body: `The pizza you selected isn't available for ${occasion.toLowerCase()}.`,
    cta: { text: 'OK' }
  };
};

type UsePizzaOptionsProps = {
  idOrCode: IdOrCode;
  skipQuery?: boolean;
};

const usePizzaOptions = ({ idOrCode, skipQuery }: UsePizzaOptionsProps): PizzaOptionsResult => {
  const [{ enabled: noSauceYumDecision }] = useDecision('fr-web-2491-yum-pizza-builder-no-sauce-hardcode');
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const isLocalized = useSelector((state: RootState) => state.domain.localization.localizedStore !== NATIONAL);
  const occasion = useSelector(localizationSelectors.occasion);
  const dispatch = useDispatch();
  const router = useRouter();
  const isDeal = useSelector((state: RootState) => !!state.domain.deal.data.id);

  const {
    data: ccData,
    loading,
    error,
    storeID
  } = useCCGetPizzaBuilderQuery({
    itemId: idOrCode.pizzaGlobalId,
    skip: skipQuery
  });

  const clientCoreData = isYumEcomm && noSauceYumDecision ? addNoSauce(ccData) : ccData;
  const data = clientCoreData;

  const dataIsValid = !loading && !error;
  const pizzaIsOOS = pizzaRecipeOOS(data);
  const pizzaNotAvailable = isDeal ? false : pizzaIsNotAvailable(data);
  const pizzaIsOOSorNotAvailable = !loading && dataIsValid && (pizzaIsOOS || pizzaNotAvailable);
  const pizzaIsAvailableOnlyForOtherOccasion = pizzaAvailableOnlyForOtherOccasion(data);
  useDeepEffect(() => {
    if ((error && isLocalized) || (data && !dataIsValid)) {
      dispatch(onGqlError(QueryNames.PIZZA_BUILDER, gqlErrorMessage(), storeID));
    }
  }, [storeID, error, isLocalized, data, dataIsValid]);

  useEffect(() => {
    if (isDeal && pizzaIsOOSorNotAvailable) {
      router.push(Routes.DEALS);
      dispatch(openModal(dealDataOOSModalDetails));
      return;
    }

    if (!isDeal && pizzaIsAvailableOnlyForOtherOccasion) {
      const otherOccasionModalContent = getAvailableForOtherOccasionModalContent(OccasionString[occasion]);
      router.push(Routes.MENU.PIZZA);
      dispatch(openModal(otherOccasionModalContent));
      return;
    }

    if (!isDeal && pizzaIsOOSorNotAvailable && !pizzaIsAvailableOnlyForOtherOccasion) {
      const name = data?.item?.name;
      const modalContent = pizzaIsOOS ? getOOSModalContent(name) : getNotAvailableModalContent();

      router.push(Routes.MENU.PIZZA);
      dispatch(openModal(modalContent));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, pizzaIsOOSorNotAvailable, pizzaIsOOS]);

  if (dataIsValid) {
    if (pizzaIsOOSorNotAvailable) {
      return {
        pizzaOptions: null,
        loading: false
      };
    }

    return {
      pizzaOptions: data || null,
      loading: loading || !isLocalized
    };
  }

  return {
    pizzaOptions: null,
    loading: loading || !isLocalized
  };
};

export default usePizzaOptions;
