import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { Grid, makeStyles } from '@material-ui/core';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import { useBuilderRef } from '../hooks/usePizzaOptions';
import BuilderSteps from '../BuilderSteps';
import PizzaBuilderDesktop from '../PizzaBuilder/PizzaBuilderDesktop';
import PizzaBuilderDesktopExpanded from '../PizzaBuilder/PizzaBuilderDesktopExpanded';
import { glutenFreeDetails } from '../PizzaBuilder/GlutenFreeDetails/GlutenFreeDetails';
import { getGlobalId } from '@/cart/helpers/getGlobalId';
import { setPizzaId } from '../../../../optimizely/utils/attributeHelpers';
import { clearPizza, selectors } from '../slice/pizza.slice';
import useOutOfStockModal from '../hooks/useOutOfStockModal';
import { openModal } from '@/localization/actions';
import Routes from '../../../router/routes';
import usePizzaValidator from '../hooks/usePizzaValidator';
import { NavBarMobileProps } from '../NavBarMobile/NavBarMobile';
import { updatePanCrustToppingModalShown } from '../slice/modal/modal.slice';
import PizzaBuilderContainerSkeleton from '@/builders/SkeletonLoaders/PizzaBuilderContainer.skeleton';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import AddToCartFooter from '@/common/AddToCartFooter';
import CenteredContainer from '@/common/CenteredContainer';
import CartTotalPrice from '../CartTotalPrice';
import { YUM_GLUTEN_FREE_CRUST_ID } from '@/builders/pizza/constants';

const useStyles = makeStyles((theme) => ({
  addToCart: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    },
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '226px'
    }
  }
}));

export interface ContainerProps extends Pick<NavBarMobileProps, 'onExit'> {
  isLocalized: boolean;
  loading: boolean;
  AddToCartButton: JSX.Element;
  recipes?: DealRecipe[];
}

type Container = React.ComponentType<ContainerProps>;
const PizzaBuilderContainer: Container = ({
  recipes,
  isLocalized,
  loading,
  onExit,
  AddToCartButton
}: ContainerProps): JSX.Element => {
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const builderRef = useBuilderRef();
  const modalDetails = useOutOfStockModal();
  const hasPizzaOptions = useSelector(selectors.hasPizzaOptions);
  const summary = useSelector(selectors.selectPizzaSummaryOption);
  const pizzaId = getGlobalId(useSelector(selectors.selectPizzaId));
  const showsQuantityAndAddToCartButton = usePizzaValidator();
  const [step, setStep] = useState<BuilderSteps>(BuilderSteps.MAIN);
  const crustId = useSelector(selectors.selectPizzaCrustGlobalId);
  const [{ enabled: cyoGlutenFreeModalDecision }] = useDecision('fr-web-3631-cyo-builder-gluten-free-modal');
  const glutenFree = summary?.glutenFree || (cyoGlutenFreeModalDecision && crustId === YUM_GLUTEN_FREE_CRUST_ID) || false;

  const [useExpandedDefaultDecision] = useDecision('cb-tavern_national_launch');
  const PizzaBuilderComponent = useExpandedDefaultDecision.enabled
    ? PizzaBuilderDesktopExpanded
    : PizzaBuilderDesktop;

  useEffect(
    () => () => {
      dispatch(clearPizza());
      dispatch(updatePanCrustToppingModalShown(false));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLocalized) {
      router.push(Routes.MENU.PIZZA);
    }
  }, [isLocalized, router]);

  useEffect(() => {
    if (!loading && hasPizzaOptions && modalDetails) dispatch(openModal(modalDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, dispatch, hasPizzaOptions]);

  // Opens a warning modal for glutenFree crusts
  useEffect(() => {
    if (glutenFree) {
      dispatch(openModal(glutenFreeDetails()));
    }
    setPizzaId(pizzaId);
  }, [glutenFree, pizzaId, dispatch]);

  if (loading || !hasPizzaOptions) {
    return <PizzaBuilderContainerSkeleton />;
  }

  const commonProps = {
    step,
    setStep,
    currentPath: router.asPath
  };

  return (
    <div data-testid="pizza-builder-container" ref={builderRef}>
      <PizzaBuilderComponent {...commonProps} recipes={recipes} />
      {showsQuantityAndAddToCartButton && (
        <AddToCartFooter>
          <CenteredContainer>
            <Grid
              container
              wrap="nowrap"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item className={classes.addToCart}>
                <CartTotalPrice />
                {AddToCartButton}
              </Grid>
            </Grid>
          </CenteredContainer>
        </AddToCartFooter>
      )}
    </div>
  );
};

export default PizzaBuilderContainer;
