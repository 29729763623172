import React from 'react';
import {
  Grid, Paper, Radio
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { formatTestId } from '../../../common/string-formatter';
import styles from './RadioBox.styles';

export interface RadioBoxProps {
  upperText: string | null | undefined,
  lowerText: string | JSX.Element,
  radioValue: string | null | undefined,
  className: string,
  checked: boolean
  onSelect: () => void
  isSoldOut: boolean;
  tabIndex?: number
  id?: string
  disabled?: boolean
  ariaRadioLabel?: string
}

const RadioBox = ({
  upperText, lowerText, radioValue, checked, onSelect, isSoldOut, className, tabIndex, id, disabled, ariaRadioLabel
}: RadioBoxProps) => {
  const classes = styles();

  return (
    <Paper
      tabIndex={tabIndex}
      key={`select-box-${upperText}-${lowerText}`}
      square={false}
      elevation={3}
      className={`${className} ${disabled ? classes.disabledBox : classes.box} ${checked ? 'selected' : ''}`}
      onClick={disabled ? () => { } : onSelect}
      data-testid={formatTestId(`option ${radioValue}`)}
      aria-label={ariaRadioLabel}
      aria-disabled={disabled}
      aria-checked={checked}
      role='radio'
    >
      <Radio
        aria-hidden
        id={id}
        value={radioValue}
        inputProps={{ title: `${upperText}-radiobutton` }}
        checked={checked}
        disabled={disabled}
        style={{
          opacity: 0, width: 1, padding: 0
        }}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
        className={classes.textContainer}
        aria-hidden
      >
        <Grid item aria-hidden>
          <Typography className={`${disabled ? classes.disabledText : ''}`}>
            {upperText}
          </Typography>
        </Grid>
        <Grid item aria-hidden>
          { // lowerText is string | JSX.Element--this check is to prevent "improper" html element nesting
            typeof lowerText === 'string' ?
              <Typography className={`${disabled ? classes.disabledText : ''}`}>
                {lowerText}
              </Typography>
              :
              lowerText
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RadioBox;
