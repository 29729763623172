import { useEffect, useState } from 'react';
import useDeepEffect from './useDeepEffect';

type UseEffectReturn = ReturnType<typeof useEffect>;
type UseEffectParams = Parameters<typeof useEffect>;
type Deps = UseEffectParams[1];
type AsyncFunc = () => Promise<void>;

/**
 * An enhanced `useEffect` which normally runs a function (aka "an effect")
 * whenever deps change. This one:
 *   - only takes async effects
 *   - runs the async effect in serial, even if deps change frequently
 *      (meaning more than one will never be happening in parallel).
 *   - skips effects if deps have changed enough to make it irrelevant
 *
 * Example use case:
 *  An API call takes 10 seconds to return, but the dependencies
 *  change every second for 10 seconds.
 *  Normally that would result in 10 API calls.
 *  When wrapped using this function, only the first and the last API call will be made.
 *
 * Usage:
 *    ```
 *      useDeepEffectEfficiently(() => {
 *        callASlowAPI(frequentChanges)
 *      }, [frequentChanges])
 *    ```
 */
export function useDeepEffectEfficiently(effect: AsyncFunc, deps: Deps): UseEffectReturn {
  const [queue, setQueue] = useState<AsyncFunc[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && queue.length > 0) {
      setLoading(true);

      const call = queue.shift();
      setQueue([]);

      call().finally(() => setLoading(false));
    }
  }, [loading, queue]);

  useDeepEffect(() => {
    setQueue([effect, ...queue]);
  }, [deps]);
}
