import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty } from '@/utils';
import { Pizza } from '../dataTransformers/builderTypes';
import { RootState } from '@/rootStateTypes';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import DealType from '@/builders/deals/DealTypes';
import { onAddToOrderButtonView } from '@/builders/deals/analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

function isPizzaValidate(pizza: Pizza) {
  return (
    pizza
    && !isEmpty(pizza.size)
    && !isEmpty(pizza.crust)
    && !isEmpty(pizza.sauce)
    && !isEmpty(pizza.cheese)
  );
}

export default function usePizzaValidator(): boolean {
  const pizza = useSelector((state: RootState) => state.domain.pizza);
  const { id: dealId, name: dealName, type: dealType } = useSelector(
    (state: RootState) => state.domain.deal.data
  );
  const { isLineup } = useLineup();
  const analytics = useAnalytics();
  const isValidPizza = isPizzaValidate(pizza);

  useEffect(() => {
    const shouldFireAnalytics = !dealId || (dealId && dealType === DealType.SINGLE_STEP);
    if (isValidPizza && shouldFireAnalytics) {
      analytics.push(() => onAddToOrderButtonView(pizza.name, dealName, dealId, isLineup));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidPizza]);

  return isValidPizza;
}
