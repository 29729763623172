import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootStateTypes';

/**
 * Equivalent to useSelector, but debounced so that your component doesn't re-render too much
 */
export function useDebounceSelector<TState = RootState, TSelected = unknown>(
  fn: (state: TState) => TSelected,
  equalFn?: (left: TSelected, right: TSelected) => boolean,
  time = 300
): TSelected {
  const [, setSate] = useState<unknown>();
  const refData = useRef<TSelected>();
  const refTimeout = useRef<NodeJS.Timeout>();

  useSelector((state: never) => {
    const nowState = fn(state);
    if (nowState === refData.current) {
      return;
    }
    if (equalFn?.(refData.current, nowState)) {
      return;
    }
    refData.current = nowState;
    clearTimeout(refTimeout.current);
    refTimeout.current = setTimeout(() => {
      setSate(refData.current);
    }, time);
  });

  useEffect(() => () => clearTimeout(refTimeout.current), []);

  return refData.current;
}
