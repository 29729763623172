import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Device, HiddenOnDevice } from '../../../../common/ResponsiveContext';
import { selectors } from '../../slice/pizza.slice';
import { usePizzaCaloriesSelector } from '../../hooks/pizzaIngredientOptionsSelectors';
import styles from './PizzaSummary.styles';
import { PIZZA_MELT_CRUST } from '../../constants';

export interface PizzaSummaryProps {
  text?: string | null;
  noBorder?: boolean;
}

const PizzaSummary = (props: PizzaSummaryProps): JSX.Element => {
  const { text, noBorder } = props;
  const classes = styles();
  const selectedCrust = useSelector(selectors.selectPizzaCrust);
  const isPizzaMelt = selectedCrust?.name === PIZZA_MELT_CRUST;
  const summaryTitle = useSelector(selectors.selectPizzaSummaryTitle);
  const servings = useSelector(selectors.selectServings);
  const calories = usePizzaCaloriesSelector();
  const className = [classes.root, noBorder && classes.noBorder].join(' ');
  // FF
  return (
    <Grid className={className}>
      <Grid item>
        {summaryTitle && (
          <>
            {!isPizzaMelt && (
              <HiddenOnDevice {...Device.MOBILE}>
                <Typography
                  variant="h4"
                  component="p"
                  data-testid="pb-sub-header"
                >
                  {text}
                </Typography>
              </HiddenOnDevice>
            )}
          </>
        )}
        {calories && servings && (
          <Typography>
            {calories}, {servings}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PizzaSummary;
