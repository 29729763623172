import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToppingsPicker from '../../ToppingsPicker';
import { updatePizzaMeatToppings, updatePizzaVeggieToppings, selectors } from '../../../../slice/pizza.slice';
import { useExtraToppingPricing } from '../../../../hooks/pizzaPricing';
import { PizzaIngredient } from '@/builders/pizza/dataTransformers/builderTypes';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '-26px',
    marginTop: '-4px'
  }
}));

const ToppingsSetterDesktop = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const meatToppingsOptions = useSelector(selectors.selectMeatToppingOptions);
  const veggieToppingsOptions = useSelector(selectors.selectVeggieToppingsOptions);

  const selectedMeatToppings = useSelector(selectors.selectPizzaMeatToppings);

  const selectedVeggieToppings = useSelector(selectors.selectPizzaVeggieToppings);

  const isPizzaSizeSplittable = useSelector(selectors.isSplittable);

  const handleAddMeatToppings = (toppings: PizzaIngredient[]) => {
    dispatch(updatePizzaMeatToppings(toppings));
  };

  const handleAddVeggieToppings = (toppings: PizzaIngredient[]) => {
    dispatch(updatePizzaVeggieToppings(toppings));
  };

  const { meatPrice, veggiePrice } = useExtraToppingPricing();

  return (
    <Grid container direction="column" className={classes.root}>
      { (meatToppingsOptions.length > 0) && (
      <ToppingsPicker
        title="Meats"
        toppingOptions={meatToppingsOptions}
        selectedToppings={selectedMeatToppings}
        onToppingsUpdate={handleAddMeatToppings}
        isPizzaSizeSplittable={isPizzaSizeSplittable}
        extraPrice={meatPrice}
      />
      )}
      { (veggieToppingsOptions.length > 0) && (
      <ToppingsPicker
        title="Veggies"
        toppingOptions={veggieToppingsOptions}
        selectedToppings={selectedVeggieToppings}
        onToppingsUpdate={handleAddVeggieToppings}
        isPizzaSizeSplittable={isPizzaSizeSplittable}
        extraPrice={veggiePrice}
      />
      )}
    </Grid>
  );
};

export default ToppingsSetterDesktop;
