import React from 'react';

const BothUnselected = ({ fillColor }: { fillColor?: string }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" aria-hidden>
    <g fill="none" fillRule="evenodd">
      <g strokeWidth="1.5">
        <g transform="translate(-106 -994) translate(106 994)">
          <circle cx="11" cy="11" r="10.25" stroke="#131313" />
          <circle cx="11" cy="11" r="8.6" fill={fillColor || '#CFCECC'} stroke="#FFF" />
        </g>
      </g>
    </g>
  </svg>
);

export default BothUnselected;
