import React from 'react';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import ExtraUnselected from '../../../../icons/PizzaToppings/ExtraIcon/ExtraUnselected';
import ExtraSelected from '../../../../icons/PizzaToppings/ExtraIcon/ExtraSelected';
import {
  ENTER_KEY_EVENT_CODE,
  KEYBOARD_ARROW_LEFT_KEYCODE,
  KEYBOARD_ARROW_RIGHT_KEYCODE,
  KEYBOARD_ENTER_KEYCODE
} from '../../../../../../common/constants';
import formattedPrice from '../../../../../../common/formattedPrice';

const useStyles = () => makeStyles(() => ({
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 0,
    height: '100%'
  },
  extraLabel: {
    padding: '0px 6px'
  },
  '.MuiFormControlLabel-root': {
    marginRight: 0
  },
  checkBox: {
    padding: '1px'
  }
}))();

interface ExtraToppingProps {
  label: string | undefined;
  onToggle: () => void;
  checked: boolean;
  extraPrice: number | undefined | null;
}

const ExtraTopping = ({
  extraPrice, onToggle, checked, label
}: ExtraToppingProps): JSX.Element => {
  const classes = useStyles();

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const enterKeyPressed = event.key === ENTER_KEY_EVENT_CODE
    || event.keyCode === KEYBOARD_ENTER_KEYCODE;
    const arrowRightKeyPressed = event.key === 'ArrowRight' || event.keyCode === KEYBOARD_ARROW_RIGHT_KEYCODE;
    const arrowLeftKeyPressed = event.key === 'ArrowLeft' || event.keyCode === KEYBOARD_ARROW_LEFT_KEYCODE;

    if (enterKeyPressed || arrowRightKeyPressed || arrowLeftKeyPressed) {
      onToggle();
    }
  };

  return (
    <FormControlLabel
      data-testid="extraCheckbox"
      className={classes.label}
      label="Extra"
      control={(
        <Checkbox
          checkedIcon={<ExtraSelected />}
          icon={<ExtraUnselected />}
          className={classes.checkBox}
          checked={checked}
          onChange={onToggle}
          onKeyDown={handleOnKeyPress}
          inputProps={{
            'aria-label': `add ${extraPrice ? formattedPrice(extraPrice) : 'charge'}`
          }}
          data-testid={`modifier-${label}-X`}
        />
      )}
    />
  );
};

export default ExtraTopping;
