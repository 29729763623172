import React from 'react';
import { useSelector } from 'react-redux';
import BuilderSteps from '../../../BuilderSteps';
import ToppingsSetterDesktop from '../../../setters/Toppings/ToppingsSetter/ToppingsSetterDesktop';
import { joinToppingDescription, Placeable } from '../../../../../common/string-formatter';
import PizzaStepAccordion from '../../../../../coreComponents/accordions/PizzaStepAccordion';
import { selectors } from '../../../slice/pizza.slice';
import { BuilderSection } from '../../../../../dataAnalytics/analyticsTypes';
import useTrackedDecision from '@/dataAnalytics/hooks/useTrackedDecision';

interface Props {
  step: BuilderSteps;
  toggleStep: (clickedStep: BuilderSteps) => void;
}

export type ToppingsExperiment = {
  deal_builder : {
    deals : string[];
  };
};

const ToppingsStepDesktop = ({
  step,
  toggleStep
}: Props): JSX.Element => {
  const meatToppings = useSelector(selectors.selectPizzaMeatToppings);
  const veggieToppings = useSelector(selectors.selectPizzaVeggieToppings);
  const toppingsChoice = joinToppingDescription([...meatToppings, ...veggieToppings] as Placeable[]);

  const [expandedToppingsDecision] = useTrackedDecision(
    'exp-web-1316-expand-toppings', { optimizelyOptions: { autoUpdate: true } }
  );

  const isExpanded = (step === BuilderSteps.TOPPINGS) || expandedToppingsDecision?.enabled;
  const onChange = () => toggleStep(BuilderSteps.TOPPINGS);
  const subHeading = toppingsChoice || 'Choose your toppings (optional)';

  return (
    <PizzaStepAccordion
      expanded={isExpanded}
      onChange={onChange}
      type={BuilderSection.TOPPINGS}
      subHeading={subHeading}
      subHeadingTestId="topping-summary-text"
      subHeadingAlert={!toppingsChoice}
    >
      <ToppingsSetterDesktop />
    </PizzaStepAccordion>
  );
};

export default ToppingsStepDesktop;
