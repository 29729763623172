import React from 'react';
import useStyles from './styles';

interface EditBuilderSkeletonProps {
  height?: string;
  marginTop?: string;
}

export const EditBuilderSkeleton = (
  { height = '400px', marginTop = '0px' }: EditBuilderSkeletonProps
): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.skeleton} style={{ height, marginTop }} data-testid="edit-builder-loading-skeleton">
        &nbsp;
    </div>
  );
};
