import { useSelector } from 'react-redux';
import { selectors } from '../../../slice/pizza.slice';
import { joinText } from '../../../../../common/string-formatter';

interface CrustSizeStepText {
  subHeadingHasError: boolean;
  subHeading: string;
  hideCaratIcon: boolean;
}

const useCrustSizeStepText = (): CrustSizeStepText => {
  const crustSelected = useSelector(selectors.selectPizzaCrust);
  const sizeSelected = useSelector(selectors.selectPizzaSize);
  const hideCaratIcon = useSelector(selectors.hideCrustStepCarat);

  switch (true as boolean) {
    case crustSelected && !sizeSelected: {
      return {
        hideCaratIcon,
        subHeading: 'Choose your crust size',
        subHeadingHasError: true
      };
    }

    case !crustSelected && !sizeSelected: {
      return {
        hideCaratIcon,
        subHeading: 'Choose your crust type and size',
        subHeadingHasError: true
      };
    }

    case sizeSelected && !crustSelected: {
      return {
        hideCaratIcon,
        subHeading: 'Choose your crust type',
        subHeadingHasError: true
      };
    }

    default: {
      return {
        hideCaratIcon,
        subHeading: joinText([sizeSelected?.name, crustSelected?.name]),
        subHeadingHasError: false
      };
    }
  }
};

export default useCrustSizeStepText;
