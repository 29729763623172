import BuilderSteps from '../../BuilderSteps';
import {
  restrictCTAQuestion,
  RestrictModalAction,
  restrictModalDescription,
  restrictModalTitle,
  restrictMultipleModalDescription,
  restrictSystemSelectedModalDescription
} from './constants';

interface Props {
  ingredients: string[];
  hasRestricted: {
    sauce: boolean;
    flavor: boolean;
    topping: {
      single: boolean;
      multiple: boolean;
    };
    multiple: boolean;
  };
  isSystemSelected: boolean;
}

const getModalBody = (
  searchValue: string,
  action: RestrictModalAction = RestrictModalAction.REMOVED
) => restrictModalDescription(action)
  .replace('[ingredient] [modifier]', searchValue.toLowerCase());

type ModalDetails = Partial<ModalContent> & { builderType: BuilderSteps };

const restrictModalDetails = ({
  hasRestricted, ingredients,
  isSystemSelected
}: Props): ModalDetails | null => {
  switch (true) {
    case hasRestricted.multiple: {
      return {
        builderType: hasRestricted.sauce ? BuilderSteps.SAUCE : BuilderSteps.MAIN,
        title: restrictModalTitle.replace('[modifier]', 'Items'),
        body: restrictMultipleModalDescription,
        bodyList: ingredients,
        extraContent: restrictCTAQuestion
      };
    }

    case hasRestricted.sauce: {
      const common = {
        builderType: BuilderSteps.SAUCE,
        title: restrictModalTitle.replace('[modifier]', 'Sauce')
      };
      if (isSystemSelected) {
        return {
          ...common,
          body: restrictSystemSelectedModalDescription
            .replace(/\[ingredient\]/g, ingredients[0].toLowerCase())
            .replace(/\[modifier\]/g, 'sauce')
        };
      }
      return {
        ...common,
        body: getModalBody(`${ingredients[0]} Sauce`, RestrictModalAction.CHANGED)
      };
    }

    case hasRestricted.flavor: {
      return {
        builderType: BuilderSteps.MAIN,
        title: restrictModalTitle.replace('[modifier]', 'Crust flavor'),
        body: getModalBody(`${ingredients[0]} Crust flavor`)
      };
    }

    case hasRestricted.topping.multiple: {
      return {
        builderType: BuilderSteps.MAIN,
        title: restrictModalTitle.replace('[modifier]', 'Topping'),
        body: restrictMultipleModalDescription,
        bodyList: ingredients,
        extraContent: restrictCTAQuestion
      };
    }

    case hasRestricted.topping.single: {
      return {
        builderType: BuilderSteps.MAIN,
        title: restrictModalTitle.replace('[modifier]', 'Topping'),
        body: getModalBody(ingredients[0])
      };
    }

    default:
      return null;
  }
};

export default restrictModalDetails;
