import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import Badge, { SoldOutBadge } from '@/coreComponents/boxes/Badge';
import Radio from '@/coreComponents/forms/Radio';
import useCrustSelection from './hooks/useCrustSelection';
import useCrustItemWithDescriptionStyles from './styles';
import { TCrustSelection } from './types';
import { useNewCrustBadge } from './hooks/useNewCrustBadge';
import NewBadge from '@/coreComponents/boxes/Badge/NewBadge';

const CrustSelection = ({ crustProps, onCrustSelect }: TCrustSelection): JSX.Element => {
  const {
    crust,
    isSelected
  } = crustProps;

  const {
    name,
    description,
    images,
    addOnPrice,
    caloriesText,
    outOfStock,
    isCrustSelected,
    screenReaderText
  } = useCrustSelection({ crust, isSelected });

  const classes = useCrustItemWithDescriptionStyles();
  const isNewCrust = useNewCrustBadge(crust.id);

  /**
   * @function handleCrustSelection
   * @description invoked when clicking on an individual crust selection tile and passes the entire crust object
   *  to update the pizza
   */
  const handleCrustSelection = () => {
    if (!isCrustSelected) {
      onCrustSelect(crust);
    }
  };

  return (
    <div className={classes.crustContainer}>
      <Paper
        role="radio"
        data-testid="crust-selection-paper"
        square={false}
        elevation={3}
        className={outOfStock ? classes.crustOutOfStock : ''}
        aria-label={screenReaderText}
        aria-disabled={outOfStock}
        aria-checked={isCrustSelected}
        onClick={handleCrustSelection}
      >
        <Box display="flex" className={classes.crustInfoContainer}>
          <div className={classes.crustImage}>
            <img
              aria-hidden
              src={images.primary || images.fallback}
              alt={`${name}-crust-image`}
            />
          </div>
          <div className={classes.crustInfo}>
            <Box display="flex" flexWrap="wrap" alignItems="center">
              <Box mr={1} data-testid="crust-name">{name}</Box>

              {isNewCrust && <NewBadge className={classes.newIcon} />}
              {addOnPrice && <Badge text={addOnPrice} dataTestId="crust-addon-price" ariaHidden />}
              {outOfStock && <SoldOutBadge data-testid="crust-out-of-stock" />}
            </Box>
            {(caloriesText && !outOfStock) && <Typography data-testid="crust-calories">{caloriesText}</Typography>}
            <Typography variant="caption" data-testid="crust-description">{description}</Typography>
          </div>
          <Box display="flex" justifyContent="end" flexGrow={1}>
            <Radio
              name={screenReaderText}
              value={name}
              checked={isCrustSelected}
              outOfStock={outOfStock}
            />
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default React.memo(CrustSelection);
