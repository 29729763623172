import { makeStyles } from '@material-ui/core';
import { largeStartBreakpoint, smallMobileStartBreakpoint } from '../../../../../materialUi/theme';

const styles = makeStyles((theme) => ({
  textContainer: {
    alignSelf: 'center'
  },
  sizeBox: {
    margin: '7px 7px 7px 0px',
    display: 'flex',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      margin: '7px 15px 10px 0px'
    },
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      minWidth: '104px',
      margin: '7px 4px 7px 4px'
    }
  },
  sizesContainer: {
    marginTop: '7px',
    marginLeft: '-4px',
    width: 'calc(100% + 8px)',
    marginBottom: '27px',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      marginRight: '-4px'
    }
  }
}));

export default styles;
