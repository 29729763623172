import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDecision } from '@optimizely/react-sdk';
import CrustItem from '../CrustItem';
import CrustSelection from '../CrustSelection';
import colors from '@/common/colors';

import { onPizzaCrustTypeClick } from '@/dataAnalytics/dataAnalyticsHelper';
import { openModal } from '@/localization/actions';
import { selectors } from '../../../slice/pizza.slice';
import { isOriginalPanCrust } from '../../../identifiers';
import {
  selectors as modalSelectors,
  updatePanCrustToppingModalShown
} from '../../../slice/modal/modal.slice';
import constants from '../../../constants';
import {
  CrustOption,
  PizzaIngredient
} from '@/builders/pizza/dataTransformers/builderTypes';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

// Rail
import DescriptionSetterInfoRail from '../../DescriptionsRail/DescriptionSetterInfoRail';
import useCrustPriority from './hooks/useCrustPriority';

interface CrustsPickerProps {
  crusts: CrustOption[];
  selectedCrust: PizzaIngredient | null;
  onSelect: (crust: CrustOption | null) => void;
  crustCaloriesBySize: (crust: CrustOption) => Nutrition | undefined;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    height: '17px',
    width: '17px',
    alignSelf: 'center',
    marginLeft: '7px',
    color: colors.gray600
  },

  crustTileGap: {
    gap: '18px'
  }
}));

const panCrustToppingMaxModalDetails = () => ({
  title: 'Too much of a good thing...',
  body: 'Stick with 5 or fewer toppings for the perfect Pan crust. You may want to remove topping(s).',
  cta: {
    text: 'OK'
  }
});

const CrustsPicker = ({
  crusts,
  selectedCrust,
  onSelect,
  crustCaloriesBySize // eslint-disable-line @typescript-eslint/no-unused-vars
}: CrustsPickerProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const numberOfSelectedToppings = useSelector(
    selectors.numberOfSelectedToppings
  );
  const [isSpecialtyPizza] = useState<boolean>(numberOfSelectedToppings > 5);
  const { isLineup } = useLineup();
  const [railVisible, setRailVisible] = useState(false);
  const isPanCrustToppingModalShown = useSelector(
    modalSelectors.isPanCrustToppingModalShown
  );

  const [useTavernNationalLaunchDecision] = useDecision('cb-tavern_national_launch');
  const isTavernNationalLaunchDecisionEnabled = useTavernNationalLaunchDecision.enabled;

  const isPanCrustAndToppingLimitReached = isOriginalPanCrust(selectedCrust)
    && numberOfSelectedToppings > constants.MAX_TOPPINGS_ALLOWED_FOR_PAN_CRUST;

  const prioritizedCrusts = useCrustPriority(crusts);

  const onPreventToAddBasedOnCrust = useCallback(() => {
    dispatch(openModal(panCrustToppingMaxModalDetails()));
    dispatch(updatePanCrustToppingModalShown(true));
  }, [dispatch]);

  useEffect(() => {
    if (
      !isPanCrustToppingModalShown
      && isPanCrustAndToppingLimitReached
      && !isSpecialtyPizza
    ) {
      onPreventToAddBasedOnCrust();
    }
  }, [
    isPanCrustToppingModalShown,
    isPanCrustAndToppingLimitReached,
    onPreventToAddBasedOnCrust,
    isSpecialtyPizza
  ]);

  const analytics = useAnalytics();
  const { pizza } = analytics.analyticsDataModel;

  const handleCrustChange = (crust: CrustOption | null) => {
    if (prioritizedCrusts.length <= 1) return;
    if (crust) {
      analytics.push(() => onPizzaCrustTypeClick(pizza.name || '', crust.name, isLineup));
    }
    onSelect(crust);
  };

  const crustDescriptions = prioritizedCrusts.map((crust) => ({
    title: crust.name || '',
    description: crust.description || ''
  }));

  const crustList = prioritizedCrusts?.map((crust) => {
    const { name, id } = crust;
    const isSelected = prioritizedCrusts.length === 1 || name === selectedCrust?.name;

    return isTavernNationalLaunchDecisionEnabled ? (
      <CrustSelection
        key={id}
        crustProps={{
          crust,
          isSelected
        }}
        onCrustSelect={handleCrustChange}
      />
    ) : (
      <CrustItem
        key={id}
        crustProps={{
          crust,
          isSelected
        }}
        onCrustSelect={handleCrustChange}
      />
    );
  });

  return (
    <>
      <Grid item container>
        <Typography variant="h3" id="crust-type-picker">
          Crust type
        </Typography>
        {!isTavernNationalLaunchDecisionEnabled
          && (
          <Tooltip title="Show Crust Descriptions" className={classes.tooltip}>
            <IconButton
              aria-label="Show Crust Descriptions"
              aria-haspopup="dialog"
              disableFocusRipple
              disableRipple
              onClick={() => setRailVisible(true)}
              data-testid="crust-picker-info-icon"
            >
              <InfoOutlinedIcon style={{ height: '17px', width: '17px' }} />
            </IconButton>
          </Tooltip>
          )}
      </Grid>
      <Grid container className={classes.crustTileGap}>
        {crustList}
      </Grid>
      {/* Descriptions Rail */}
      <DescriptionSetterInfoRail
        visible={railVisible}
        onClose={() => setRailVisible(false)}
        descriptionList={crustDescriptions}
        contentTitle="Crust Types"
      />
    </>
  );
};

export default CrustsPicker;
