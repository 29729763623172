const restrictCTAQuestion = 'Do you want to continue with this selection?';

const restrictModalTitle = '[modifier] not available with this crust!';

enum RestrictModalAction {
  REMOVED = 'removed',
  CHANGED = 'changed'
}

const restrictModalDescription = (
  action: RestrictModalAction = RestrictModalAction.REMOVED
): string => {
  const restrictedMsg = `${'Sorry, [ingredient] [modifier] is not available for'
  + ` this crust and will be ${action}. `}${restrictCTAQuestion}`;

  return restrictedMsg;
};

const restrictSystemSelectedModalDescription = 'Sorry, [ingredient] [modifier] is not available for'
  + ' this crust. We have changed the selection to a different [modifier].';

const restrictMultipleModalDescription = 'Sorry, some ingredients are not available for'
  + ' this crust and will be removed, see below:';

export {
  restrictCTAQuestion,
  RestrictModalAction,
  restrictModalTitle,
  restrictModalDescription,
  restrictMultipleModalDescription,
  restrictSystemSelectedModalDescription
};
