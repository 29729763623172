import {
  IngredientOptionWithPortions,
  PizzaIngredientOption,
  PortionChoice
} from '@/builders/pizza/dataTransformers/builderTypes';
import Portion from '@/common/Portion';

interface PizzaIngredientOptionWithPortion extends PizzaIngredientOption {
  portion: PortionChoice;
}

const defaultSortPortionsMap = {
  none: 0,
  light: 0,
  regular: 0,
  extra: 0
};

const sortPortionsMap = Object.values(Portion)
  .reduce((acc, cur, index) => ({ ...acc, [cur]: index }), defaultSortPortionsMap);

const sortOptions = (
  a: PizzaIngredientOptionWithPortion, b: PizzaIngredientOptionWithPortion
) => sortPortionsMap[a.portion] - sortPortionsMap[b.portion];

export const getAvailablePortions = (cheeses: IngredientOptionWithPortions[]) : PortionChoice[] => cheeses
  .filter((cheese) => !cheese.outOfStock)
  .flatMap((cheese) => cheese.portions)
  .filter((portion): portion is PizzaIngredientOptionWithPortion => !portion?.outOfStock
        && !!portion?.portion)
  .sort(sortOptions)
  .map((portion) => portion?.portion)
  .filter((portion): portion is PortionChoice => !!portion);
