/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Select,
  Tooltip,
  Typography,
  IconButton
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import SCCFPicker from '../../SCCFPicker';
import PortionPicker from '../PortionPicker';
import Portion from '../../../../../common/Portion';
import { joinText } from '@/common/string-formatter';
import { selectors } from '../../../slice/pizza.slice';
import { useSauceNutrition } from '../../../hooks/pizzaIngredientOptionsSelectors';
import { onSauceSelect } from '@/dataAnalytics/dataAnalyticsHelper';
import { isTheNoSauceOption } from '../../../identifiers';
import styles from './SaucePicker.styles';
import { SCCFSection } from '@/dataAnalytics/analyticsTypes';
import {
  IngredientOptionWithPortions,
  PizzaIngredientOption,
  PortionChoice
} from '@/builders/pizza/dataTransformers/builderTypes';
import { RootState } from '@/rootStateTypes';
import { FormChangeEvent } from '@/common/eventTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
// Rail
import DescriptionSetterInfoRail from '../../DescriptionsRail/DescriptionSetterInfoRail';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import { PIZZA_MELT_CRUST, DETROIT_PIZZA } from '@/builders/pizza/constants';

export interface SaucePickerProps {
  sauces: IngredientOptionWithPortions[];
  selectedSauce: PizzaIngredientOption;
  onSelect: (option: PizzaIngredientOption | undefined) => void;
}

const SaucePicker = ({
  sauces,
  selectedSauce,
  onSelect
}: SaucePickerProps): ReactElement => {
  // Hide Premium Tomato Sauce if not Detroit-Style
  const currentCrust = useSelector(selectors.selectPizzaCrustOption);
  const isDetroitStyle = currentCrust?.name === DETROIT_PIZZA;
  const filteredSauces = isDetroitStyle
    ? sauces
    : sauces.filter((sauce) => sauce?.name !== 'Premium Tomato Sauce');
  const selectedCrust = useSelector(selectors.selectPizzaCrust);
  const isPizzaMelt = selectedCrust?.name === PIZZA_MELT_CRUST;

  // Hide Premium Tomato Sauce if not Detroit-Style ^^^

  const [railVisible, setRailVisible] = useState(false);
  const classes = styles();
  const sauceDropdown = useRef<HTMLInputElement>(null);
  const NoSauce = filteredSauces.find(isTheNoSauceOption);
  const NoSaucePortion = NoSauce?.portions?.find(
    ({ portion }) => portion === Portion.NONE
  );
  const { isLineup } = useLineup();

  const analytics = useAnalytics();
  const { pizza } = analytics.analyticsDataModel;

  const analyticsPush = (
    sauceName: string | null | undefined,
    portion?: string,
    isExpanded = true
  ) => {
    analytics.push(() => onSauceSelect(sauceName, portion, pizza.name || '', isExpanded, isLineup));
  };

  const parentSauce = useSelector((state: RootState) => selectors.selectRootSauceById(state, selectedSauce.id));

  const resetNoSauce = () => {
    analyticsPush(NoSaucePortion?.name, Portion.NONE);
    onSelect(NoSaucePortion);
  };

  const updateSauce = (
    sauceName: string | null | undefined,
    portion: PortionChoice | undefined
  ) => {
    if (portion) {
      const newParentSauce = filteredSauces.find(
        ({ name }) => name === sauceName
      );

      const newSelectedSauce = newParentSauce?.portions?.find(
        (a) => a.portion === portion
      );

      onSelect(newSelectedSauce);
      analyticsPush(sauceName, portion);
    }
  };

  const handleSelectSauceName = (event: FormChangeEvent) => {
    const sauceName = event.target.value as string | null | undefined;

    if (sauceName === NoSauce?.name) return resetNoSauce();
    const portion: PortionChoice | undefined = selectedSauce.portion === Portion.NONE
      ? Portion.REGULAR
      : selectedSauce.portion;

    return updateSauce(sauceName, portion);
  };

  const handleSelectSaucePortion = (event: unknown, portion: PortionChoice) => {
    if (portion === Portion.NONE) return resetNoSauce();
    return updateSauce(parentSauce.name, portion);
  };

  const selectedItem = () => joinText([
    parentSauce?.name,
    selectedSauce?.portion !== Portion.NONE && selectedSauce?.portion
  ]);

  const onExpanded = () => {
    sauceDropdown.current?.focus();
  };

  const onCollapsed = () => {
    analyticsPush(parentSauce?.name, selectedSauce?.portion, false);
  };

  const sauceList = filteredSauces.map((sauce, index) => {
    const sauceName = sauce.name ? sauce.name : undefined;
    return (
      <option
        key={`${index}-${sauceName}`}
        value={sauceName}
        disabled={sauce.outOfStock}
        aria-label={`Choose a sauce ${selectedSauce.name}`}
      >
        {sauce.outOfStock ? `${sauceName} (sold out)` : sauceName}
      </option>
    );
  });

  const sauceDescriptions = filteredSauces
    .filter((sauce) => sauce?.name && sauce.name.toLowerCase() !== 'no sauce')
    .map((sauce) => ({
      title: sauce.name || '',
      description: sauce.description || ''
    }));

  const portions = Array.from(
    new Set([NoSaucePortion, ...parentSauce.portions])
  )
    .map((portion) => portion?.portion)
    .filter((portion): portion is PortionChoice => !!portion);

  const sauceChoices = (
    <Grid>
      <label htmlFor="sauceTypesSetter" className={classes.subtitle}>
        Choose a sauce
      </label>
      <FormControl style={{ width: '100%' }}>
        <Select
          data-testid="sauceType-setter"
          disableUnderline
          native
          inputRef={sauceDropdown}
          value={parentSauce?.name}
          inputProps={{
            name: parentSauce?.name,
            id: 'sauceTypesSetter',
            className: classes.selectBox,
            tabIndex: 0,
            'aria-hidden': 'false'
          }}
          IconComponent={() => (
            <ExpandMoreIcon
              classes={{ root: classes.selectBoxIcon }}
              data-testid="expand-more-icon-sauce"
            />
          )}
          onChange={handleSelectSauceName}
        >
          {sauceList}
        </Select>
      </FormControl>
      {(portions.length > 1 && !(isPizzaMelt)) && (
        <PortionPicker
          onSelection={handleSelectSaucePortion}
          portions={portions}
          label={SCCFSection.SAUCE}
          selectedPortion={selectedSauce.portion || portions[0]}
        />
      )}
    </Grid>
  );
  const selectedSize = useSelector(selectors.selectPizzaSize);
  const sauceEditable = !!(selectedSize && selectedCrust)
      && ((sauces && sauces.length > 1) || (sauces[0].portions && sauces[0].portions.length > 1));

  return (
    <>
      <Grid item className={classes.whitespace}>
        <Box>
          <Typography variant="h3">Sauce</Typography>
        </Box>
        <Tooltip title="Show Sauce Descriptions" className={classes.tooltip}>
          <IconButton
            aria-label="Show Sauce Descriptions"
            aria-haspopup="dialog"
            disableFocusRipple
            disableRipple
            onClick={() => setRailVisible(true)}
            data-testid="sauce-picker-info-icon"
          >
            <InfoOutlinedIcon style={{ height: '17px', width: '17px' }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <SCCFPicker
        testId="sauce-picker"
        nutrition={useSauceNutrition(selectedSauce)}
        selectedItem={selectedItem()}
        imgUrl={selectedSauce?.image}
        onExpanded={onExpanded}
        onCollapsed={onCollapsed}
        label={SCCFSection.SAUCE}
        editable={sauceEditable}
      >
        {sauceChoices}
      </SCCFPicker>

      {/* Description Rail */}
      <DescriptionSetterInfoRail
        visible={railVisible}
        onClose={() => setRailVisible(false)}
        descriptionList={sauceDescriptions}
        contentTitle="Sauce Types"
      />
    </>
  );
};

export default SaucePicker;
