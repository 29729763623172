import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Placement as CCPlacement } from '@pizza-hut-us-development/client-core';
import { useDispatch } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import fontStyles from '../../../../../common/fontStyles';
import { AddButton } from '../../ExpandButtons';
import ExpandablePicker from '../../ExpandablePicker';
import ToppingPreference from '../ToppingPreference';
import AccessibilityDescribedBy from '../../../../../common/AccessibilityDescribedBy';
import { getJoinedText } from '@/common/string-formatter';
import colors from '../../../../../common/colors';
import Placement from '../../../../../common/Placement';
import { SoldOutBadge } from '../../../../../coreComponents/boxes/Badge';
import { onPizzaToppingClick } from '@/dataAnalytics/dataAnalyticsHelper';
import {
  ToppingsAction,
  ToppingsExtraAnalytics,
  ToppingsPlacementAnalytics,
  ToppingsSelectionAnalytics
} from '@/dataAnalytics/analyticsTypes';
import CalorieDisplay from '../../CalorieDisplay';
import getCaloriesPerUnitText from '../../../dataTransformers/caloriesPerUnit';
import { PlacementChoice } from '@/builders/pizza/dataTransformers/builderTypes';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { GRILLED_SIRLOIN_STEAK, HIDE_EXTRA_TOPPINGS } from '@/builders/pizza/constants';
import { openModal } from '@/localization/actions';

const useStyles = makeStyles(() => ({
  card: {
    ...fontStyles.textLink,
    cursor: ({ isOutOfStock }: Partial<ToppingItemProps>) => (!isOutOfStock ? 'pointer' : undefined),
    minHeight: '50px',
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)',
    transition: 'height 0.1s'
  },
  calories: {
    opacity: ({ isSelected }: Partial<ToppingItemProps>) => (isSelected ? 1 : 0),
    height: '0px'
  },
  collapsedContent: {
    height: '100%',
    padding: '16px 0px 0px 0px',
    justifyContent: 'flex-start'
  },
  cardText: {
    ...fontStyles.bodyPriceAndCalorie,
    paddingLeft: '9px',
    opacity: ({ isOutOfStock }: Partial<ToppingItemProps>) => (isOutOfStock ? 0.5 : 1)
  },
  cardAction: {
    padding: '4px 0px 0px 0px',
    height: '100%',
    alignItems: 'flex-start',
    margin: 0
  },
  image: {
    height: '50px',
    width: '50px',
    padding: '7px 1px 1px 7px',
    '&.MuiAvatar-colorDefault': {
      textAlign: 'center'
    },
    opacity: ({ isOutOfStock }: Partial<ToppingItemProps>) => (isOutOfStock ? 0.5 : 1)
  },
  outOfStockTitle: {
    padding: '7px 5px 0 0',
    '& > .badgeText': {
      display: 'inline-block',
      marginLeft: '5px',
      color: colors.gray601
    }
  },
  expandedContainer: {},
  collapse: {}
}));

interface OnChangeTopping {
  hasExtra: boolean;
  placement: PlacementChoice | CCPlacement;
  isSelected: boolean;
}

export interface ToppingItemProps {
  id: string | undefined;
  name: string | null | undefined;
  imgUrl: string | undefined;
  isOutOfStock: boolean | undefined;
  isSelected: boolean;
  placementSelected: PlacementChoice | CCPlacement;
  placementAvailable: boolean | undefined;
  extraAvailable: boolean;
  extraSelected: boolean;
  extraPrice: number | undefined | null;
  nutrition: Partial<Nutrition> | null;
  onChange: (value: OnChangeTopping) => void;
  reachedToppingLimit?: boolean;
  reachedPanCrustLimit?: boolean;
  reachedBNYCrustLimit?: boolean;
}

const ToppingItem = ({
  id,
  name,
  imgUrl,
  isOutOfStock,
  isSelected,
  placementSelected,
  placementAvailable,
  extraAvailable,
  extraSelected,
  extraPrice,
  nutrition,
  onChange,
  reachedToppingLimit,
  reachedPanCrustLimit,
  reachedBNYCrustLimit
}: ToppingItemProps): JSX.Element => {
  const classes = useStyles({ isSelected, isOutOfStock });

  const analytics = useAnalytics();
  const { pizza } = analytics.analyticsDataModel;
  const { isLineup } = useLineup();
  const dispatch = useDispatch();
  const [{ enabled: placementLimitEnabled }] = useDecision('fr-web3480-half_topping_enforcement');
  const [{ enabled: allowExtraCCPepEnabled }] = useDecision('fr-web-3602-allow_extra_cc_pep');

  const addAnalytics = (action: ToppingsAction) => {
    const addOrExtraOverLimit = (action === ToppingsSelectionAnalytics.ADD
        || action === ToppingsExtraAnalytics.EXTRA)
      && (reachedToppingLimit || reachedPanCrustLimit);
    if (!addOrExtraOverLimit) {
      analytics.push(() => onPizzaToppingClick(pizza.name ?? '', name, action, isLineup));
    }
  };

  const onToggle = () => {
    const action = isSelected
      ? ToppingsSelectionAnalytics.UNCHECK
      : ToppingsSelectionAnalytics.ADD;
    addAnalytics(action);
    onChange({
      isSelected: !isSelected,
      placement: placementSelected,
      hasExtra: extraSelected
    });
  };

  const onExtraClick = () => {
    const action = extraSelected
      ? ToppingsExtraAnalytics.UNEXTRA
      : ToppingsExtraAnalytics.EXTRA;
    addAnalytics(action);
    onChange({
      isSelected,
      placement: placementSelected,
      hasExtra: !extraSelected
    });
  };

  const onPlacementClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: placement } = event.target;
    addAnalytics(
      ToppingsPlacementAnalytics[
        placement.toUpperCase() as keyof typeof ToppingsPlacementAnalytics
      ]
    );
    if (placementLimitEnabled && event.target.value === Placement.WHOLE && (reachedToppingLimit || reachedBNYCrustLimit)) {
      dispatch(
        openModal({
          title: 'Topping overload!',
          body: 'More of that topping would exceed the limit our chef allows for an evenly cooked pizza',
          cta: {
            text: 'GOT IT'
          }
        })
      );
      return;
    }
    onChange({
      isSelected,
      placement: placement as PlacementChoice,
      hasExtra: extraSelected
    });
  };

  const ariaRoleCheckbox = (checked: boolean) => ({
    role: 'checkbox',
    'aria-checked': checked
  });

  const testIdLabel = name?.toLowerCase().replace(' ', '-');

  const caloriesDisplay = !isOutOfStock && isSelected ? (
    <CalorieDisplay
      label={`${testIdLabel}-topping`}
      nutrition={nutrition}
      includeProductName={false}
    />
  ) : undefined;

  const hidePreference = (!extraAvailable && !placementAvailable) || isOutOfStock;

  const extraAllowed = allowExtraCCPepEnabled ? name !== GRILLED_SIRLOIN_STEAK : !HIDE_EXTRA_TOPPINGS.includes(name ?? '');

  return (
    <ExpandablePicker
      providedAriaRole={ariaRoleCheckbox}
      startsExpanded={!isOutOfStock && isSelected}
      onToggle={!isOutOfStock ? onToggle : undefined}
      ariaDescribedby={`${getJoinedText(name)}-id`}
      enabled={!isOutOfStock && isSelected}
      expandButton={(expanded) => (isOutOfStock ? (
        <div className={classes.outOfStockTitle}>
          <SoldOutBadge />
        </div>
      ) : (
        <>
          <AddButton
            testIdLabel={testIdLabel}
            testId={id}
            expanded={expanded}
          />
          <AccessibilityDescribedBy
            describedById={name}
            describedByText={`${name} selected${
              nutrition ? `, ${getCaloriesPerUnitText(nutrition)}` : ''
            }`}
          />
        </>
      ))}
      heading={name}
      subheading={caloriesDisplay}
      id={id}
      expandedContent={
        !hidePreference ? (
          <ToppingPreference
            label={testIdLabel}
            showPlacement={placementAvailable}
            showExtraToggle={extraAvailable && extraAllowed}
            toggleExtra={extraSelected}
            selectedPlacement={placementSelected}
            handleToggleExtra={onExtraClick}
            handlePlacementChange={onPlacementClick}
            extraPrice={
              extraPrice && placementSelected !== Placement.WHOLE
                ? extraPrice / 2
                : extraPrice
            }
          />
        ) : undefined
      }
      classes={classes}
      testId={`${name}-topping-adder-picker`}
      pickerType="topping-picker"
      imgUrl={imgUrl}
    />
  );
};

export default ToppingItem;
