import { snakeToCamel, CamelPHDCartItem, transformCartItemInputToPhdCartItem } from '@pizza-hut-us-development/client-core';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { legacyPizzaTransformer } from './useCCTransformLegacyCartItem/helpers/legacyPizzaTransformer';

const useCCTransformLegacyPizzaBuilderOutputToPriceablePizza = () => {
  const handleLegacyPizzaBuilderPizzaToPriceableItem = (pizzaBuilderPizza:BuiltPizza):CamelPHDCartItem => {
    const cartItemInputPizza = legacyPizzaTransformer(pizzaBuilderPizza);
    const realPizza = snakeToCamel(transformCartItemInputToPhdCartItem(cartItemInputPizza)) as CamelPHDCartItem;

    return realPizza;
  };

  return { handleLegacyPizzaBuilderPizzaToPriceableItem };
};

export default useCCTransformLegacyPizzaBuilderOutputToPriceablePizza;
