import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getJoinedText } from '../string-formatter';

interface AccessibilityDescribedByProps {
  describedById: string | null | undefined
  describedByText: string
}
const useStyles = makeStyles(() => ({
  displayNone: {
    display: 'none'
  }
}));

const AccessibilityDescribedBy = ({ describedById = '', describedByText = '' }: AccessibilityDescribedByProps) => {
  const classes = useStyles();
  const computedId = `${getJoinedText(describedById)}-id`;

  return (
    <Typography id={computedId} className={classes.displayNone}>
      {describedByText}
    </Typography>
  );
};

export default AccessibilityDescribedBy;
