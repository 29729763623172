const cheeseOOSBody = 'We\'re sorry, this restaurant has run out of cheese.';

const sauceOOSBody = 'We\'re sorry, this restaurant has run out of [sauce].'
  + ' '
  + 'Do you want to continue and choose another sauce?';

const toppingsOOSBody = 'We\'re sorry, this restaurant has run out of [toppings].'
  + ' '
  + 'You can continue to customize your recipe or choose another one.';

const flavorOOSBody = 'We\'re sorry, this restaurant has run out of the [flavor] crust flavor.';

const multipleOOSBody = 'We\'re sorry, this restaurant has run out of [ingredients].';

const pizzaOOSBody = 'We\'re sorry, this restaurant has run out of some ingredients in the'
  + ' [pizzaName]. You can choose another recipe or create your own';

export {
  pizzaOOSBody,
  cheeseOOSBody,
  sauceOOSBody,
  flavorOOSBody,
  toppingsOOSBody,
  multipleOOSBody
};
