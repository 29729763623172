import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { largeStartBreakpoint } from '@/materialUi/theme';

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: 8,
    marginBottom: '2.6rem',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      marginBottom: '1.6rem'
    }
  },
  skeleton: {
    paddingTop: '50px',
    margin: '0 auto',
    maxWidth: '1152px',
    alignContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      paddingTop: '10px',
      width: '90%'
    }
  },
  titleBox: {
    width: '64rem',
    height: '5.6rem',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      width: '70%',
      height: '2.8rem'
    }
  },
  smallBox: {
    height: '1.6rem',
    width: '22%'
  },
  productBox: {
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      width: '100%'
    },
    height: '7.1rem'
  },
  confirmBox: {
    height: '3.2rem'
  },
  mobileHeader: {
    width: '70%',
    justifyContent: 'space-between',
    marginBottom: '1.7rem'
  },
  navButtonBox: {
    width: '28px',
    height: '28px'
  }
}));

const PizzaBuilderContainerSkeleton = (): JSX.Element => {
  let isDesktop = false;
  if (typeof window !== 'undefined') {
    isDesktop = window.innerWidth > 650;
  }
  const {
    skeleton,
    box,
    titleBox,
    smallBox,
    productBox,
    confirmBox,
    navButtonBox,
    mobileHeader
  } = useStyles();
  if (isDesktop) {
    return (
      <Grid data-testid="pbc-skeleton" container justifyContent="flex-start" className={`${skeleton}`}>
        <Skeleton variant="rect" className={`${box} ${titleBox}`} />
        <Skeleton
          variant="rect"
          className={`${box} ${smallBox}`}
          style={{ marginBottom: '6.3rem' }}
        />
        <Skeleton variant="rect" className={`${box} ${productBox}`} />
        <Skeleton variant="rect" className={`${box} ${productBox}`} />
        <Skeleton
          variant="rect"
          className={`${box} ${productBox}`}
          style={{ marginBottom: '5rem' }}
        />
        <Skeleton
          variant="rect"
          className={`${box} ${smallBox}`}
          style={{ marginBottom: '4.7rem' }}
        />
        <Skeleton variant="rect" className={`${box} ${confirmBox}`} />
      </Grid>
    );
  }
  return (
    <Grid data-testid="pbc-mobile-skeleton" container justifyContent="center" className={`${skeleton}`}>
      <Grid container className={`${mobileHeader}`}>
        <Skeleton variant="rect" className={`${box} ${navButtonBox}`} />
        <Skeleton variant="rect" className={`${box} ${titleBox}`} />
      </Grid>
      <Skeleton
        variant="rect"
        className={`${box} ${titleBox}`}
        style={{ marginBottom: '2.3rem' }}
      />
      <Skeleton variant="rect" className={`${box} ${productBox}`} />
      <Skeleton variant="rect" className={`${box} ${productBox}`} />
      <Skeleton variant="rect" className={`${box} ${productBox}`} />
      <Skeleton variant="rect" className={`${box} ${productBox}`} />
    </Grid>
  );
};

export default PizzaBuilderContainerSkeleton;
