import { createStyles, makeStyles } from '@material-ui/core';
import { mobileStartBreakpoint } from '@/materialUi/theme';

const useCrustItemWithDescriptionStyles = makeStyles((theme) => createStyles({
  crustContainer: {
    width: '48%',
    marginTop: '10px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    }
  },
  crustInfoContainer: {
    height: '100px'
  },
  crustImage: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      display: 'flex'
    },
    '& img': {
      maxHeight: '100%',
      borderTopLeftRadius: '8px',
      [theme.breakpoints.down(mobileStartBreakpoint)]: {
        borderBottomLeftRadius: '8px'
      }
    }
  },
  crustInfo: {
    padding: '8px 0 0 10px'
  },
  crustOutOfStock: {
    opacity: 0.5
  },
  newIcon: {
    marginRight: '8px'
  }

}));

export default useCrustItemWithDescriptionStyles;
