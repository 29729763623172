import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { cartSelectors } from '@/cart/cartSelectors';
import Routes from '@/router/routes';

export const useEditBuilderSkeleton = (): boolean => {
  const router = useRouter();
  const isCartLoading = useSelector(cartSelectors.loading);

  const currentRoute = router.pathname;

  return isCartLoading && currentRoute.includes(Routes.EDIT_BUILDER);
};
