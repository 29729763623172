import { Collapse, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import debounce from 'just-debounce';
import { updatePizzaSpecialInstructions } from '../../../slice/pizza.slice';
import RemainingCharactersInput from '@/common/RemainingCharactersInput';
import LinkCaret, { CaretDirection } from '@/coreComponents/links/LinkCaret';
import {
  BuilderSection,
  BuilderSectionAction
} from '@/dataAnalytics/analyticsTypes';
import { onPizzaBuilderSectionClick } from '@/dataAnalytics/dataAnalyticsHelper';
import telemetry from '@/telemetry';
import { RootState } from '@/rootStateTypes';
import { SPECIAL_INSTRUCTIONS_ID } from '@/builders/pizza/setters/SpecialInstructions/constants';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import colors from '@/common/colors';
import { mobileStartBreakpoint } from '@/materialUi/theme';

const useStyles = makeStyles((theme) => ({
  specialInstructionsField: {
    width: '100%',
    maxWidth: '341px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '341px'
    }
  },
  specialInstructionsCollapse: {
    marginTop: '8px'
  },
  buttonContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '200px'
    }
  },
  fieldContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1
  },
  container: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginTop: '26px',
    transition: 'height 0.25s'
  },
  openHeight: {
    height: '100px'
  },
  closedHeight: {
    height: '60px'
  },
  hiddenButton: {
    display: 'none'
  },
  specialInstructionsText: {
    color: colors.gray900
  }
}));

interface SpecialInstructionsSetterDesktopProps {
  value: string;
}

const SpecialInstructionsSetterDesktop = ({ value }: SpecialInstructionsSetterDesktopProps) => {
  const { isLineup } = useLineup();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(!!value);
  useEffect(() => { if (value) setIsOpen(true); }, [value]);

  const onTextEdit = useMemo(() => debounce(
    (content: string) => dispatch(updatePizzaSpecialInstructions(content)),
    300
  ), [dispatch]);

  const classes = useStyles();

  const analytics = useAnalytics();
  const { deal, pizza } = analytics.analyticsDataModel;

  const addAnalytics = (action: BuilderSectionAction) => {
    analytics.push(() => onPizzaBuilderSectionClick(
      pizza.name || '',
      deal.deal_id || '',
      deal.deal_name || '',
      action,
      true,
      isLineup
    ));
  };

  const openAccordion = () => {
    setIsOpen(true);
    addAnalytics(BuilderSection.SPECIAL_INSTRUCTIONS);
    telemetry.addCustomEvent('pizza-builder-special-instructions-accordion-click');
  };

  return (
    <Grid container>
      <Grid container wrap="nowrap">
        <Grid item md zeroMinWidth>
          <div className={`${classes.container} ${isOpen ? classes.openHeight : classes.closedHeight}`}>
            <div className={!isOpen ? classes.buttonContainer : classes.hiddenButton}>
              <LinkCaret
                text="Special instructions"
                onClick={openAccordion}
                caretDirection={CaretDirection.DOWN}
                dataTestId="pb-special-req"
                ariaExpanded={isOpen}
                ariaLabel="Special instructions"
                className={classes.specialInstructionsText}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Collapse
                className={classes.specialInstructionsCollapse}
                in={isOpen}
                data-testid="special-instructions-expander"
                collapsedSize={20}
                timeout={250}
              >
                <div className={classes.specialInstructionsField}>
                  {isOpen && (
                    <RemainingCharactersInput
                      id={SPECIAL_INSTRUCTIONS_ID}
                      initialValue={value}
                      onChange={onTextEdit}
                      label="Special instructions"
                      ariaLabel="Special instructions Expanded"
                    />
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  value: state.domain.pizza.specialInstructions
});

export default connect(mapStateToProps)(SpecialInstructionsSetterDesktop);
