import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';

import ProductId from '@/common/ProductId';

import { RootState } from '@/rootStateTypes';

import { NewBadgeDecisionVariables } from '@/builders/pizza/setters/Toppings/ToppingsPicker/hooks/useNewToppingBadge';

export const useNewCrustBadge = (modifierId: string): boolean => {
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const featureFlagDecision = isYumEcomm ? 'fr-dtg-802-yum-show-new-badge' : 'cb-tavern_national_launch';

  const [{
    enabled: isFeatureFlagEnabled,
    variables: decisionVariables
  }] = useDecision(featureFlagDecision, { autoUpdate: true });

  if (!isFeatureFlagEnabled) {
    return false;
  }

  const decisionNewCrustsBadge = (decisionVariables as NewBadgeDecisionVariables)?.new_crusts_badge;
  const newCrustsIdList = decisionNewCrustsBadge?.crusts?.ids ?? [];
  const { globalId: crustId } = new ProductId(modifierId);

  return newCrustsIdList.includes(crustId);
};

export default useNewCrustBadge;
