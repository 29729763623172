import { useGetPizzaBuilderQuery } from '@pizza-hut-us-development/client-core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { transformPizzaBuilderPizza } from './transformPizzaBuilderPizza';
import { OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { PizzaBuilderOptions } from '@/graphql/dynamicQueries/pizza';
import { RootState } from '@/rootStateTypes';
import { useTemporaryCCContext } from '../temporaryCCContext';
import useProduct from '@/graphql/hooks/variables/useProductItem';
import ProductId from '@/common/ProductId';
import { useGetProductsToHideQuery } from '@/api/reduxToolkit/hideProducts';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';
import { sauceHardcodedConstant } from '@/clientCore/hardCodingHelpers/WEB-2491-yumPizzaNoSauce/helpers';

type CCPizzaBuilderTransformed = {
  data?: PizzaBuilderOptions;
  loading: boolean | null;
  error?: unknown | boolean | null;
  storeID: string | null;
};
export const useCCGetPizzaBuilderQuery = ({ itemId = '', skip = false }): CCPizzaBuilderTransformed => {
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [storeTimezone, setStoreTimeZone] = useState<string | undefined>(undefined);
  const [{ enabled: noSauceYumDecision }] = useDecision('fr-web-2491-yum-pizza-builder-no-sauce-hardcode');

  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const { isOptimizelyApiLoading } = useTemporaryCCContext();

  const { globalId } = new ProductId(itemId);
  const storeSpecificId = useProduct('items', itemId).variables?.itemID;
  const idToUse = isYumEcomm ? globalId : storeSpecificId;

  const { data: hideProductData } = useGetProductsToHideQuery();

  useEffect(() => {
    if (storeDetails?.storeNumber) setStoreNumber(storeDetails.storeNumber);
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const { data, isLoading, error } = useGetPizzaBuilderQuery(
    { storeNumber, itemId: idToUse },
    { skip: !storeNumber || skip || (isOptimizelyApiLoading && !isYumEcomm) }
  );

  if (skip) {
    return {
      data: undefined,
      loading: false,
      error: null,
      storeID: null
    };
  }

  if (isLoading || !storeNumber || (isOptimizelyApiLoading && !isYumEcomm)) {
    return {
      data: undefined,
      loading: true,
      error: null,
      storeID: null
    };
  }

  const transformedBuilderPizza = data
    ? transformPizzaBuilderPizza(data, occasion, storeTimezone ?? '', hideProductData)
    : null;

  if (debugEnabled) {
    transformationDebugLogging(
      'useCCGetPizzaBuilderQuery',
      'useGetPizzaBuilderQuery',
      data,
      transformedBuilderPizza,
      debugMode,
      [DebugModeOptions.PRODUCTS, DebugModeOptions.MENU, DebugModeOptions.TRANSFORMATIONS]
    );
  }

  const addNoSauce = (transformedPizza?: PizzaBuilderOptions | null): PizzaBuilderOptions | undefined => {
    if (!transformedPizza) return undefined;
    const hasNoSauce = Boolean(transformedPizza?.sauces?.find((sauce) => sauce?.name?.toLowerCase() === 'no sauce'));
    if (!hasNoSauce) {
      const newTransformedPizza = { ...transformedPizza };
      if (
        'sauces' in newTransformedPizza
        && 'sauces' in transformedPizza
        && Boolean(transformedPizza.sauces?.length)
        && Boolean(newTransformedPizza.sauces?.length)
      ) newTransformedPizza?.sauces?.push(sauceHardcodedConstant(transformedPizza?.sauces?.length));
    }
    return transformedPizza;
  };

  return {
    data:
      (isYumEcomm && noSauceYumDecision ? addNoSauce(transformedBuilderPizza) : transformedBuilderPizza) ?? undefined,
    loading: isLoading,
    error,
    storeID: storeNumber
  };
};
