import React from 'react';
import { Link, LinkProps, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  caret: {
    position: 'relative',
    top: '8px'
  }
}));

export enum CaretDirection {
  UP, DOWN
}

interface LinkCaretProps extends LinkProps {
  text: string,
  caretDirection?: CaretDirection
  onClick?: () => void
  ariaLabel?: string
  ariaExpanded?: boolean
  ariaHidden?: boolean
  dataTestId?: string
  className?: string
}

const IconDirection = ({ direction }: any) => {
  const classes = useStyles();

  switch (direction) {
    case CaretDirection.DOWN:
      return (<ExpandMoreIcon className={classes.caret} />);
    case CaretDirection.UP:
      return (<ExpandLessIcon className={classes.caret} />);
    default:
      return null;
  }
};

const LinkCaret = ({
  text, caretDirection, onClick, tabIndex, ariaLabel = '', ariaExpanded, ariaHidden, dataTestId, className
}: LinkCaretProps) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    component="button"
    onClick={onClick}
    tabIndex={tabIndex}
    aria-label={ariaLabel}
    aria-expanded={ariaExpanded}
    data-testid={dataTestId}
    className={className}
    aria-hidden={ariaHidden}
  >
    { text }
    <IconDirection direction={caretDirection} />
  </Link>
);
export default LinkCaret;
