import {
  Box, Grid, Typography, Divider
} from '@material-ui/core';
import React from 'react';
import InfoRail from '@/common/components/InfoRail';
import { DescriptionSetterInfoRailProps } from './types';

const DescriptionSetterInfoRail = ({
  visible,
  onClose,
  descriptionList,
  contentTitle
}: DescriptionSetterInfoRailProps): JSX.Element => (
  <InfoRail visible={visible} onClose={onClose}>
    <Box p={3}>
      <Box pb={1}>
        <Typography component="p" variant="h3">{contentTitle}</Typography>
      </Box>
      <Grid container spacing={2}>
        {descriptionList.map((item, index) => (
          <Grid key={index} item>
            <Box pt={2} pb={3}>
              <Box pb={1}>
                <Typography component="p" style={{ fontWeight: 800, fontSize: '1.4rem' }}>
                  {item.title}
                </Typography>
              </Box>
              <Typography component="p">
                {item.description}
              </Typography>
            </Box>
            {descriptionList.length - 1 > index && <Divider />}
          </Grid>
        ))}
      </Grid>
    </Box>
  </InfoRail>
);

export default DescriptionSetterInfoRail;
