import { makeStyles } from '@material-ui/core/styles';
import { mobileStartBreakpoint, smallMobileStartBreakpoint } from '../../../../materialUi/theme';
import colors from '../../../../common/colors';

const styles = makeStyles((theme) => ({
  root: {
    padding: '1.5em 0.5em 1em 0',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      borderBottom: `1px solid ${colors.gray400}`,
      padding: '0.5em 0.5em 1em 0.5em'
    },
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      marginBottom: '1em'
    }
  },
  title: {
    marginTop: '23px'
  },
  noBorder: {
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      borderBottom: 'none'
    }
  }
}));

export default styles;
