import {
  createStyles, Grid, Paper, Typography
} from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge, { SoldOutBadge } from '../../../../../coreComponents/boxes/Badge';
import formattedPrice from '../../../../../common/formattedPrice';
import Radio from '../../../../../coreComponents/forms/Radio';
import { largeStartBreakpoint, mobileStartBreakpoint } from '../../../../../materialUi/theme';
import colors from '../../../../../common/colors';
import { formatTestId } from '../../../../../common/string-formatter';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import useCrustSelection from '../CrustSelection/hooks/useCrustSelection';
import { CrustOption } from '@/builders/pizza/dataTransformers/builderTypes';

type TypeCrustItemStyle = {
  outOfStock: boolean;
};

const useStyles = makeStyles((theme) => createStyles({
  crustSubGrid: {
    borderRadius: '4px',
    overflow: 'hidden'
  },
  crustImageContainer: {
    marginRight: '15px',
    width: '90px',
    height: '90px'
  },
  outOfStock: {
    disabled: true,
    pointerEvents: 'none'
  },
  outOfStockImage: {
    opacity: 0.5
  },
  outOfStockText: {
    color: colors.gray601
  },
  radioItem: {
    width: '292px',
    margin: '7px 0px 16px 0px',
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      margin: '7px 0px 10px 0px'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    }
  },
  image: {
    margin: '0 10px 1px 0',
    objectFit: 'cover',
    width: '90px',
    height: '90px',
    borderRadius: '8px',
    opacity: ({ outOfStock }: TypeCrustItemStyle) => (outOfStock ? 0.5 : 1)
  }
}));

export interface CrustItemProps {
  crustProps: {
    crust: CrustOption;
    isSelected: boolean;
  };
  onCrustSelect: (crust: CrustOption) => void;
}

const CrustItem = ({
  crustProps, onCrustSelect
}: CrustItemProps): JSX.Element => {
  const {
    crust,
    isSelected
  } = crustProps;

  const {
    name,
    images,
    outOfStock,
    isCrustSelected,
    addOnPrice,
    caloriesText,
    screenReaderText
  } = useCrustSelection({ crust, isSelected });

  const classes = useStyles({ outOfStock: (outOfStock ?? false) });

  /**
   * @function handleCrustSelection
   * @description invoked when clicking on an individual crust selection tile and passes the entire crust object
   *  to update the pizza
   */
  const handleCrustSelection = () => {
    if (!isCrustSelected) {
      onCrustSelect(crust);
    }
  };

  return (
    <Paper
      className={
        outOfStock
          ? `${classes.radioItem} ${classes.outOfStock}`
          : ` ${classes.radioItem}`
      }
      square={false}
      elevation={3}
      aria-label={screenReaderText}
      tabIndex={-10}
      data-testid={formatTestId(`option ${name}`)}
      onClick={handleCrustSelection}
      aria-disabled={outOfStock}
      aria-checked={isCrustSelected}
      role="radio"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        className={outOfStock ? classes.outOfStock : classes.crustSubGrid}
        wrap="nowrap"
        aria-hidden
      >
        <div className={classes.crustImageContainer}>
          <ResponsiveImageRender
            title=""
            testId={name || ''}
            className={outOfStock ? classes.outOfStockImage : ''}
            mobile={images.primary || images.fallback}
            desktop={images.primary || images.fallback}
            altText={`${name}-crust-img`}
            ariaHidden
            fallbackImage={images.fallback}
          />
        </div>
        <Grid container direction="column" wrap="nowrap" aria-hidden>
          <Typography className={outOfStock ? classes.outOfStockText : ''}>
            {name}
          </Typography>
          {caloriesText && !outOfStock && (
            <Typography className={outOfStock ? classes.outOfStockText : ''}>
              {caloriesText}
            </Typography>
          )}
          {addOnPrice && <Badge text={addOnPrice} ariaHidden />}
          {outOfStock && <SoldOutBadge />}
        </Grid>
        <Radio
          name={screenReaderText}
          checked={isCrustSelected}
          value={name}
          outOfStock={outOfStock}
        />
      </Grid>
    </Paper>
  );
};

export default CrustItem;
