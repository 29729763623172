import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectors } from '../slice/pizza.slice';
import { isCheese, isMeat, isVeggie } from '../identifiers';
import { RuleItem } from '../dataTransformers/builderTypes';
import logger from '@/common/logger';
import telemetry from '@/telemetry';

const usePricingRules = (isItemType: (x: RuleItem) => boolean) => {
  const pricingRules = useSelector(selectors.selectPricingRules);
  return pricingRules?.find(isItemType)?.price;
};

const useExtraCheesePricing = (): number | null => usePricingRules(isCheese) || null;

export interface ExtraToppingPrices {
  meatPrice: number | undefined;
  veggiePrice: number | undefined;
}
const useExtraToppingPricing = (): ExtraToppingPrices => {
  const meatPrice = usePricingRules(isMeat);
  const veggiePrice = usePricingRules(isVeggie);
  const crust = useSelector(selectors.selectPizzaCrust);
  const size = useSelector(selectors.selectPizzaSize);

  const pizzaId = useSelector(selectors.selectPizzaId);
  useEffect(() => {
    if (crust?.id && size?.id && (!meatPrice || !veggiePrice)) {
      const error = new Error('Error: no price found for meat/veggie. Unable to display additional'
        + ' pricing message');
      logger.withoutTelemetry.error(error.message);
      telemetry.addNoticeError(error, { pizzaId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crust, size, meatPrice, veggiePrice]);

  return { meatPrice, veggiePrice };
};

export {
  useExtraCheesePricing,
  useExtraToppingPricing
};
