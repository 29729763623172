import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenteredContainer from '../../../../common/CenteredContainer';
import fontStyles from '../../../../common/fontStyles';
import HeaderText from '../../../../common/HeaderText';
import BuilderSteps from '../../BuilderSteps';
import PizzaSummary from '../../steps/PizzaSummary';
import SauceCheeseFlavorStepDesktop from '../../steps/SauceCheeseFlavor/SauceCheeseFlavorStepDesktop';
import SizeCrustStepDesktop from '../../steps/SizeCrust/SizeCrustStepDesktop';
import ToppingsStepDesktop from '../../steps/Toppings/ToppingsStepDesktop';
import SpecialInstructionsSetterDesktop from '../../setters/SpecialInstructions/SpecialInstructionsSetterDesktop';
import { selectors } from '../../slice/pizza.slice';
import { isCreateYourOwnPizza } from '../../identifiers';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import {
  EditBuilderSkeleton,
  useEditBuilderSkeleton
} from '@/common/EditBuilderSkeleton';
import Routes from '@/router/routes';
import { PIZZA_MELT_CRUST } from '../../constants';
import { Device, HiddenOnDevice } from '@/common/ResponsiveContext';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    marginTop: '3.5em'
  },
  imageGrid: {
    overflow: 'hidden',
    marginLeft: '59px',
    minWidth: '448px',
    minHeight: '337px',
    maxHeight: '337px'
  },
  price: {
    ...fontStyles.h2Mobile,
    margin: '3px 23px 0 0'
  },
  img: {
    borderRadius: '10px',
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    objectFit: 'contain'
  },
  accordionBox: {
    width: '600px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    }
  },
  builderStepContainerExpanded: {
    display: 'grid',
    gap: '24px'
  }
}));

export interface DesktopPizzaBuilderProps {
  step: BuilderSteps;
  setStep: (step: BuilderSteps) => void;
  currentPath?: string;
  recipes?: DealRecipe[];
}

const PizzaBuilderDesktop = (props: DesktopPizzaBuilderProps): JSX.Element => {
  const {
    step, setStep, currentPath, recipes
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const summary = useSelector(selectors.selectPizzaSummaryOption);
  const summaryTitle = useSelector(selectors.selectPizzaSummaryTitle);
  const selectedCrust = useSelector(selectors.selectPizzaCrust);
  const crusts = useSelector(selectors.selectCrustOptions);
  const showEditBuilderSkeleton = useEditBuilderSkeleton();
  const isPizzaMelt = selectedCrust?.name === PIZZA_MELT_CRUST;
  const selectedRecipe = useSelector(selectors.selectPizzaOptions);

  const hidePreview = summary
    ? isCreateYourOwnPizza(summary) || !summary?.image
    : false;

  const toggleStep = (clickedStep: BuilderSteps) => {
    if (step === clickedStep) {
      setStep(BuilderSteps.MAIN);
    } else {
      setStep(clickedStep);
    }
  };

  // Auto expands crusts when requirements are met.
  useEffect(() => {
    if (
      crusts
      && window.innerWidth > 650
      && crusts?.length > 1
      && currentPath === Routes.DEALS
    ) setStep(BuilderSteps.CRUST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CenteredContainer>
      <Grid className={classes.root} data-testid="build-your-pizza-desktop">
        <Grid item md={7}>
          <HeaderText
            text={
              recipes && recipes.length > 1
                ? selectedRecipe?.summary.displayName
                : summaryTitle
            }
          />
          <PizzaSummary text="Build your pizza" />
        </Grid>

        <Grid container wrap="nowrap">
          <Grid item className={classes.accordionBox}>
            <Grid>
              {showEditBuilderSkeleton ? (
                <EditBuilderSkeleton />
              ) : (
                <>
                  {!isPizzaMelt && (
                    <SizeCrustStepDesktop
                      step={step}
                      toggleStep={toggleStep}
                      currentPath={currentPath}
                    />
                  )}

                  <ToppingsStepDesktop step={step} toggleStep={toggleStep} />

                  <SauceCheeseFlavorStepDesktop
                    step={step}
                    toggleStep={toggleStep}
                  />
                </>
              )}
            </Grid>

            {!showEditBuilderSkeleton && <SpecialInstructionsSetterDesktop />}
            <CaloriesDisclaimer />
          </Grid>
          {hidePreview || isPizzaMelt ? null : (
            <HiddenOnDevice {...Device.MOBILE}>
              <Grid item className={classes.imageGrid}>
                <img
                  loading="lazy"
                  src={summary?.image}
                  alt="your-pizza"
                  className={classes.img}
                  data-testid="pizza-builder-product-image"
                />
              </Grid>
            </HiddenOnDevice>
          )}
        </Grid>
      </Grid>
    </CenteredContainer>
  );
};

export default PizzaBuilderDesktop;
