import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BuilderSteps from '../../../BuilderSteps';
import SizeCrustSetterDesktop from '../../../setters/SizeCrust/SizeCrustSetterDesktop';
import PizzaStepAccordion from '../../../../../coreComponents/accordions/PizzaStepAccordion';
import { selectors } from '../../../slice/pizza.slice';
import { BuilderSection } from '../../../../../dataAnalytics/analyticsTypes';
import useCrustSizeStepText from '../useCrustSizeStepText';

interface Props {
  step: BuilderSteps;
  toggleStep: (clickedStep: BuilderSteps) => void;
  currentPath?: string;
}

const SizeCrustStepDesktop = ({
  step, toggleStep, currentPath
}: Props): JSX.Element => {
  const [expandedWithEnter, setExpandedWithEnter] = useState(false);
  const sizeSelected = useSelector(selectors.selectPizzaSize);
  const { subHeadingHasError, subHeading, hideCaratIcon } = useCrustSizeStepText();

  const isExpanded = (step === BuilderSteps.CRUST) || (step === BuilderSteps.SIZE);
  const onChange = (event: KeyboardEvent) => {
    toggleStep(BuilderSteps.CRUST);
    setExpandedWithEnter(event.key === 'Enter');
  };
  const onExpanded = () => {
    if (expandedWithEnter) {
      document.getElementById(sizeSelected?.id || '')?.focus();
    }
  };

  return (
    <PizzaStepAccordion
      expanded={isExpanded}
      onChange={onChange}
      onExpanded={onExpanded}
      type={BuilderSection.CRUST}
      subHeading={subHeading}
      subHeadingAlert={subHeadingHasError}
      hideExpandIcon={hideCaratIcon}
    >
      <SizeCrustSetterDesktop toggleStep={toggleStep} currentPath={currentPath} />
    </PizzaStepAccordion>
  );
};

export default SizeCrustStepDesktop;
