import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';

import ProductId from '@/common/ProductId';

import { RootState } from '@/rootStateTypes';

export type NewBadgeDecisionVariables = {
  new_toppings_badge: { toppings: { ids: string[] } };
  new_crusts_badge: { crusts: { ids: string[] } };
  new_recipes_badge: { recipes: { ids: string[] } };
  tavern_pizza_ids: { pizzas: { ids: string[] } };
};

const useNewToppingBadge = (id: string):boolean => {
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const featureFlagDecision = isYumEcomm ? 'fr-dtg-802-yum-show-new-badge' : 'cb-tavern_national_launch';

  const [{
    enabled: isFeatureFlagEnabled,
    variables: decisionVariables
  }] = useDecision(featureFlagDecision, { autoUpdate: true });

  // return false if FF is turned OFF
  if (!isFeatureFlagEnabled) {
    return false;
  }

  // get decision variables -> new_toppings_badge
  const decisionNewToppingsBadge = (decisionVariables as NewBadgeDecisionVariables)?.new_toppings_badge;

  // get list of new toppings ids from decision variables
  const newToppingsIdList = decisionNewToppingsBadge?.toppings?.ids ?? [];

  // extract topping id
  const { globalId: toppingId } = new ProductId(id);

  return newToppingsIdList?.includes(toppingId as string);
};

export default useNewToppingBadge;
