import { useMemo } from 'react';

import { CrustOption } from '@/builders/pizza/dataTransformers/builderTypes';

const useCrustPriority = (crusts: CrustOption[]):CrustOption[] => {
  const prioritizedCrusts = useMemo(() => crusts.sort((a, b) => a.priority! - b.priority!), [crusts]);

  return prioritizedCrusts;
};

export default useCrustPriority;
