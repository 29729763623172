import { makeStyles } from '@material-ui/core';
import colors from '../../../common/colors';

const styles = makeStyles(() => ({
  textContainer: {
    alignSelf: 'center'
  },
  disabledText: {
    color: colors.gray601
  },
  disabledBox: {
    cursor: 'default',
    minHeight: '64px',
    minWidth: '189px',
    display: 'flex',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  box: {
    cursor: 'pointer',
    minHeight: '64px',
    minWidth: '189px',
    display: 'flex',
    userSelect: 'none',
    '&.selected': {
      border: 'solid 2px #e71316',
      borderRadius: '8px',
      backgroundColor: colors.white
    },
    '&:active': {
      boxShadow: 'inset 2px 3px 6px 0 rgba(0, 0, 0, 0.3)'
    }
  }
}));

export default styles;
