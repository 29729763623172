import React from 'react';

const RightUnselected = ({ fillColor }: { fillColor?: string }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" aria-hidden>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-154 -994) translate(154 994) matrix(-1 0 0 1 22 0)">
            <circle cx="11" cy="11" r="10.25" fill="#FFF" stroke="#131313" strokeWidth="1.5" />
            <circle cx="11" cy="11" r="8.6" fill={fillColor || '#CFCECC'} stroke="#FFF" strokeWidth="1.5" />
            <path fill="#FFF" d="M11 1.65c5.164 0 9.35 4.186 9.35 9.35s-4.186 9.35-9.35 9.35V1.65z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RightUnselected;
