import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import fontStyles, { sharedFontStyle } from '../../../../../common/fontStyles';
import colors from '../../../../../common/colors';
import Portion from '../../../../../common/Portion';
import { PortionChoice } from '@/builders/pizza/dataTransformers/builderTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1, 0)
  },
  subtitle: {
    ...sharedFontStyle
  },
  fullwidth: {
    width: '100%'
  },
  grouped: {
    ...fontStyles.body,
    fontSize: 14,
    textTransform: 'capitalize',
    marginTop: '6px',
    padding: '5px 0',
    display: 'flex',
    flex: 1,
    '&:hover': {
      backgroundColor: colors.white,
      border: `solid 1px ${colors.red}`
    },
    '&.Mui-selected': {
      color: colors.white,
      backgroundColor: colors.red,
      fontWeight: 600,
      'box-shadow': 'inset 1px 1px 4px 0 rgba(0, 0, 0, 0.5)',
      '&:hover': {
        backgroundColor: colors.red
      }
    }
  }
}));

export interface PortionPickerProps {
  onSelection: (event: unknown, portion: PortionChoice) => void;
  portions: PortionChoice[];
  label: string;
  selectedPortion: string;
}

const PortionPicker = ({
  onSelection,
  portions,
  selectedPortion = Portion.NONE,
  label
}: PortionPickerProps): ReactElement => {
  const classes = useStyles();

  const filteredPortions = Object.values(Portion).filter(
    (portion) => portions.includes(portion)
  );

  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle}>Amount</Typography>
      <ToggleButtonGroup
        size="large"
        value={selectedPortion}
        exclusive
        className={classes.fullwidth}
        onChange={onSelection}
        classes={{ grouped: classes.grouped }}
      >
        {filteredPortions.map((portionOption: PortionChoice) => (
          <ToggleButton
            key={portionOption}
            value={portionOption}
            data-testid={`modifier-${label}-${portionOption}`}
            aria-label={portionOption === Portion.NONE ? `Amount ${portionOption}` : portionOption}
          >{portionOption}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default PortionPicker;
