import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, updatePizzaCrust, updatePizzaSize } from '../../../slice/pizza.slice';
import { selectCrustOptionForSize } from '../../../slice/selectors/crustSelectors';
import CrustsPicker from '../CrustsPicker';
import SizesPicker from '../SizesPicker';
import { crustCaloriesBySize } from '../../../hooks/pizzaIngredientOptionsSelectors';
import { extractGlobalId } from '../../../identifiers';
import useRestrictRuleModal from '../../../hooks/useRestrictRuleModal';
import { openModal } from '../../../../../localization/actions';
import BuilderSteps from '../../../BuilderSteps';
import { selectSizeOptionForCrust } from '../../../slice/selectors/sizeSelectors';
import { PizzaPayload } from '../../../slice/withCrustRules/withCrustRules';
import { CrustOption, PizzaIngredientOption } from '@/builders/pizza/dataTransformers/builderTypes';
import Routes from '@/router/routes';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '-17px'
  }
}));

interface Props {
  toggleStep: (clickedStep: BuilderSteps) => void;
  currentPath?: string;
}

const SizeCrustSetterDesktop = ({ toggleStep, currentPath }: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const restrictRulesModal = useRestrictRuleModal();

  // pizza selectors
  const selectedSize = useSelector(selectors.selectPizzaSize);
  const selectedCrust = useSelector(selectors.selectPizzaCrust) || undefined;
  const sizes = useSelector(selectors.selectSizeOptions);
  const crusts = useSelector(selectors.selectCrustOptions) || undefined;
  const pizzaOptions = useSelector(selectors.selectPizzaOptions);
  const hasCollapsed = useRef(false);

  const getRestrictModalDetails = (
    size: PizzaIngredientOption | null,
    crust: CrustOption | null,
    updater: { payload: PizzaPayload | null; type: string }
  ): ModalContent | undefined => restrictRulesModal({
    sizeId: size?.id,
    crustId: crust?.id,
    ctaCallback: (builderType: BuilderSteps) => {
      dispatch(updater);
      toggleStep(builderType);
    }
  });

  const selectSize = (size: PizzaIngredientOption | null) => {
    const crustToBeSelected = selectCrustOptionForSize(
      pizzaOptions,
      size,
      selectedCrust
    );
    const modalDetails = getRestrictModalDetails(
      size,
      crustToBeSelected,
      updatePizzaSize(size)
    );

    const genericCrusts = ['Hand Tossed Pizza', 'Original Pan® Pizza', 'Thin ‘N Crispy®'];

    if (!hasCollapsed.current && ((size && selectedCrust) !== undefined)
        && genericCrusts.includes(selectedCrust?.name as string)) {
      hasCollapsed.current = true;
      toggleStep(BuilderSteps.TOPPINGS);
    }

    return modalDetails
      ? dispatch(openModal(modalDetails))
      : dispatch(updatePizzaSize(size));
  };

  const selectCrust = (crust: CrustOption | null) => {
    const sizeToBeSelected = selectSizeOptionForCrust(pizzaOptions, crust);
    const modalDetails = getRestrictModalDetails(
      sizeToBeSelected,
      crust,
      updatePizzaCrust(crust)
    );

    if (selectedSize !== null && crust !== null) toggleStep(BuilderSteps.TOPPINGS);
    if (currentPath === Routes.DEALS && crust !== null) toggleStep(BuilderSteps.TOPPINGS);

    return modalDetails
      ? dispatch(openModal(modalDetails))
      : dispatch(updatePizzaCrust(crust));
  };
  return (
    <Grid container className={classes.container}>
      <SizesPicker
        sizes={sizes}
        selectedSize={selectedSize}
        onSelect={selectSize}
      />
      <CrustsPicker
        crusts={crusts || []}
        selectedCrust={selectedCrust || null}
        onSelect={selectCrust}
        crustCaloriesBySize={crustCaloriesBySize(extractGlobalId(selectedSize))}
      />
    </Grid>
  );
};

export default SizeCrustSetterDesktop;
