import makeStyles from '@material-ui/core/styles/makeStyles';
import fontStyles, { sharedFontStyle } from '../../../../../common/fontStyles';
import colors from '../../../../../common/colors';

const styles = makeStyles(() => ({
  title: {
    ...fontStyles.textLink,
    ...fontStyles.h3Mobile
  },
  subtitle: {
    ...sharedFontStyle
  },
  whitespace: {
    marginBottom: '7px',
    display: 'flex'
  },
  card: {
    cursor: 'pointer',
    height: '64px'
  },
  cardText: {
    ...fontStyles.bodyPriceAndCalorie
  },
  image: {
    height: '64px',
    width: '64px'
  },
  selectBox: {
    ...fontStyles.formFieldHintText,
    border: `1px solid ${colors.gray900}`,
    borderRadius: '8px',
    padding: '7px',
    display: 'block',
    width: '100%',
    lineHeight: '14px',
    height: '26px',
    '&:focus': {
      borderRadius: '8px'
    },
    marginTop: '7px',
    marginBottom: '0px'
  },
  selectBoxIcon: {
    top: 'calc(50% - 20px)',
    right: '5px',
    height: '40px',
    width: '26px',
    position: 'absolute',
    pointerEvents: 'none'
  },
  selectTitle: {
    ...fontStyles.formFieldLabel,
    marginBottom: '8px'
  },
  outOfStockHeader: {
    paddingLeft: '16px',
    '& > .badgeText': {
      display: 'inline-block',
      marginLeft: '5px'
    }
  },
  tooltip: {
    height: '17px',
    width: '17px',
    alignSelf: 'center',
    marginLeft: '7px',
    color: colors.gray600
  }
}));

export default styles;
