import { useSelector } from 'react-redux';
import { Placement } from '@pizza-hut-us-development/client-core';
import { selectors } from '../slice/pizza.slice';
import Portion from '../../../common/Portion';
import isHalfTopping from '../../../common/isHalfTopping';
import {
  PlacementChoice,
  PizzaIngredientOption,
  PortionChoice,
  PizzaIngredient,
  IngredientOptionWithPortions
} from '../dataTransformers/builderTypes';

const useGlobalSizeAndCrustIds = () => {
  const crustId = useSelector(selectors.selectPizzaCrustGlobalId);
  const sizeId = useSelector(selectors.selectPizzaSizeGlobalId);
  return { crustId, sizeId };
};

const sizeAndCrustQualifierNutrition = (
  nutrition: Nutrition[] | null | undefined,
  sizeId: string,
  crustId: string | undefined
) => nutrition?.filter(
  ({ qualifiers = [] }) => qualifiers.includes(sizeId) && (crustId && qualifiers.includes(crustId))
);

const useRecipeDefaultCheese = (): PizzaIngredientOption => {
  const cheeseOptions = useSelector(selectors.selectCheeseOptions);
  const selectedCheese = cheeseOptions.find(({ selected }) => selected) || cheeseOptions[0];

  const selectedPortion = selectedCheese?.portions
    .find(({ selected }) => selected);

  return selectedPortion
    ?? selectedCheese?.portions.find(({ portion }) => portion === Portion.REGULAR)
    ?? selectedCheese?.portions[0];
};

const useRecipeDefaultToppings = (): PizzaIngredientOption[] => {
  const options = useSelector(selectors.selectPizzaOptions);

  const recipeDefaults = options && [
    ...options?.veggieToppings || [],
    ...options?.meatToppings || []
  ]
    .filter(({ selected }) => selected);

  return recipeDefaults || [];
};

const getCaloriesAndUnitsByPlacementAndPortion = (
  nutrition: Nutrition[] | null | undefined,
  portion: PortionChoice | undefined,
  placement: PlacementChoice | Placement | undefined,
  sizeCrustIds: { sizeId: string; crustId: string | undefined }
): Partial<Nutrition> => {
  const { sizeId, crustId } = sizeCrustIds;
  const toppingNutrition = sizeAndCrustQualifierNutrition(
    nutrition,
    sizeId,
    crustId
  )?.find(
    ({ portion: ingredientPortion }) => ingredientPortion?.toLowerCase()
          === (portion || Portion.REGULAR)
  );

  const divisor = isHalfTopping(placement) ? 2 : 1;
  const calories = toppingNutrition?.calories
    ? Math.ceil(toppingNutrition.calories / divisor)
    : undefined;
  const unit = toppingNutrition?.unit || 'slice';

  return {
    calories,
    unit
  };
};

const useCaloriesByPlacementAndPortionText = (
  ingredientOption: PizzaIngredientOption,
  ingredient: PizzaIngredient | undefined
): Partial<Nutrition> | null => {
  const sizeCrustIds = useGlobalSizeAndCrustIds();

  if (!ingredient) return null;

  return getCaloriesAndUnitsByPlacementAndPortion(
    ingredientOption.nutrition,
    ingredient.portion,
    ingredient.placement,
    sizeCrustIds
  );
};

const useCalculatedToppingCalories = (
  toppingsOptions: PizzaIngredientOption[],
  selectedToppings: PizzaIngredient[]
): number => {
  const sizeCrustIds = useGlobalSizeAndCrustIds();

  const sumCaloriesReducer = (accumulator: number, ingredient: PizzaIngredient) => {
    // TODO: remove default when portion is fixed from being null
    const toppingOption = toppingsOptions
      ?.find((option) => ingredient.id && option?.id?.includes(ingredient.id));
    const { calories } = getCaloriesAndUnitsByPlacementAndPortion(
      toppingOption?.nutrition,
      ingredient.portion,
      ingredient.placement,
      sizeCrustIds
    );

    return accumulator + (calories || 0);
  };
  return selectedToppings?.reduce(sumCaloriesReducer, 0);
};

const crustCaloriesBySize = (sizeId: string | undefined) => (
  { nutrition }: PizzaIngredientOption
): Nutrition | undefined => (sizeId
  ? nutrition?.find((x) => x.qualifiers?.includes(sizeId))
  : undefined);

const useIngredientNutrition = (
  nutrition: Nutrition[] | null | undefined,
  portion?: PortionChoice
): Nutrition | undefined => {
  const { sizeId, crustId } = useGlobalSizeAndCrustIds();
  const selectedNutrition = sizeAndCrustQualifierNutrition(nutrition, sizeId, crustId) || [];

  return selectedNutrition
    .find(({ portion: nutritionPortion }) => !portion || nutritionPortion?.toLowerCase() === portion.toLowerCase());
};

const useRecipeDefaultSauce = (): IngredientOptionWithPortions => {
  const sauceOptions = useSelector(selectors.selectSauceOptions) || [];
  const [recipeDefault = sauceOptions[0]] = sauceOptions.filter(
    ({ selected }) => selected
  );

  return recipeDefault;
};

const useRecipeDefaultFlavor = (): Partial<PizzaIngredientOption> => useSelector(
  selectors.selectDefaultFlavor
) || {};

const useSauceNutrition = (sauce: PizzaIngredientOption | undefined): Nutrition | undefined => {
  const sauceOptions = useSelector(selectors.selectSauceOptions);
  const selectedSauce = sauce?.id
    ? sauceOptions
      .filter(({ id }) => id && sauce?.id?.includes(id))
      .flatMap(({ portions }) => portions)
      .find(({ portion }) => portion?.toLowerCase() === sauce?.portion?.toLowerCase())
    : undefined;
  const { sizeId, crustId } = useGlobalSizeAndCrustIds();
  const nutrition = sizeAndCrustQualifierNutrition(selectedSauce?.nutrition, sizeId, crustId);

  return nutrition && nutrition.length === 1 ? nutrition[0]
    : nutrition?.find((nutrtionInfo) => nutrtionInfo?.portion?.toLowerCase() === selectedSauce?.portion?.toLowerCase());
};

const useSauceCalories = (sauce: PizzaIngredientOption | undefined) => useSauceNutrition(sauce)?.calories || 0;

const useIngredientCalories = (
  ingredient: PizzaIngredientOption | undefined
) => useIngredientNutrition(ingredient?.nutrition, ingredient?.portion)?.calories || 0;

const usePizzaCaloriesSelector = (): string | undefined => {
  const meatToppingsOptions = useSelector(selectors.selectMeatToppingOptions);
  const veggieToppingOptions = useSelector(selectors.selectVeggieToppingsOptions);

  const selectedMeatToppings = useSelector(selectors.selectPizzaMeatToppings);
  const selectedVeggieToppings = useSelector(selectors.selectPizzaVeggieToppings);
  const selectedCrust = useSelector(selectors.selectPizzaCrustOption);
  const selectedFlavor = useSelector(selectors.selectPizzaFinisherOption);
  const selectedCheese = useSelector(selectors.selectPizzaCheeseOption);
  const selectedCrustNutrition = selectedCrust?.nutrition?.[0];
  const selectedSauce = useSelector(selectors.selectPizzaSauceOption);

  const estimatedTotalCalories = (Number(selectedCrustNutrition?.calories) || 0)
    + Number(useSauceCalories(selectedSauce))
    + Number(useIngredientCalories(selectedCheese))
    + Number(useIngredientCalories(selectedFlavor))
    + Number(
      useCalculatedToppingCalories(meatToppingsOptions, selectedMeatToppings)
    )
    + Number(
      useCalculatedToppingCalories(veggieToppingOptions, selectedVeggieToppings)
    );

  return selectedCrustNutrition && `${estimatedTotalCalories} cal/${selectedCrustNutrition.unit || 'slice'}`.toLowerCase();
};

export {
  useRecipeDefaultToppings,
  useRecipeDefaultCheese,
  usePizzaCaloriesSelector,
  useSauceNutrition,
  useIngredientNutrition,
  useRecipeDefaultFlavor,
  useRecipeDefaultSauce,
  crustCaloriesBySize,
  useCaloriesByPlacementAndPortionText
};
