import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  skeleton: {
    width: '100%',
    maxWidth: '728px',
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    animation: '$pulse 1.6s ease-in-out 0.5s infinite',
    borderRadius: '8px'
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.4
    },
    '100%': {
      opacity: 1
    }
  }
}));
