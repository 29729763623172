import React from 'react';
import { useSelector } from 'react-redux';
import BuilderSteps from '../../../BuilderSteps';
import SauceCheeseFlavorSetterDesktop
  from '../../../setters/SauceCheeseFlavor/SauceCheeseFlavorSetter/SauceCheeseFlavorSetterDesktop';
import PizzaStepAccordion from '../../../../../coreComponents/accordions/PizzaStepAccordion';
import { selectors } from '../../../slice/pizza.slice';
import { BuilderSection } from '../../../../../dataAnalytics/analyticsTypes';
import { getAvailablePortions } from '@/builders/pizza/setters/utils';

interface Props {
  step: BuilderSteps;
  toggleStep: (clickedStep: BuilderSteps) => void;
}

const SauceCheeseFlavorStepDesktop = ({
  step, toggleStep
}: Props): JSX.Element => {
  const sauceSelected = useSelector(selectors.selectPizzaSauce);
  const cheeseSelected = useSelector(selectors.selectPizzaCheese);
  const cheeseString = cheeseSelected ? `, ${cheeseSelected.name}` : '';

  const sauces = useSelector(selectors.selectSauceOptions);
  const cheeses = useSelector(selectors.selectCheeseOptions);
  const sauceNotEditable = sauces && sauces.length === 1 && sauces[0].portions && sauces[0].portions.length === 1;
  const cheeseNotEditable = getAvailablePortions(cheeses).length === 1;
  const selectedSize = useSelector(selectors.selectPizzaSize);
  const selectedCrust = useSelector(selectors.selectPizzaCrust);

  const hideExpansion = !(selectedSize && selectedCrust) || (sauceNotEditable && cheeseNotEditable);

  const subHeading = !sauceSelected
    ? 'Choose a sauce (optional)'
    : `${sauceSelected.name}${cheeseString}`;

  const isExpanded = step === BuilderSteps.SAUCE
    || step === BuilderSteps.CHEESE
    || step === BuilderSteps.FLAVOR;

  return (
    <PizzaStepAccordion
      expanded={isExpanded}
      type={BuilderSection.SCCF}
      subHeading={subHeading}
      onChange={() => toggleStep(BuilderSteps.SAUCE)}
      subHeadingAlert={!sauceSelected}
      hideExpandIcon={hideExpansion}
    >
      <SauceCheeseFlavorSetterDesktop />
    </PizzaStepAccordion>
  );
};

export default SauceCheeseFlavorStepDesktop;
