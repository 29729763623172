import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { sortBy } from 'remeda';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { SeeAllButton } from '../../ExpandButtons';
import { stripCharacters } from '@/common/string-formatter';
import Portion from '../../../../../common/Portion';
import { toIngredientWithPortionAndPlacement } from '../../../dataTransformers/toIngredient';
import { openModal } from '@/localization/actions';
import { selectors } from '../../../slice/pizza.slice';
import {
  onPizzaToppingClick,
  onPizzaToppingSeeAllClick
} from '@/dataAnalytics/dataAnalyticsHelper';
import {
  ToppingsExtraAnalytics,
  ToppingsSelectionAnalytics
} from '@/dataAnalytics/analyticsTypes';
import ToppingItemOptimized, {
  OnToppingItemChangeProps
} from '../ToppingItem/ToppingItemOptimized';
import {
  selectors as modalSelectors,
  updatePanCrustToppingModalShown
} from '../../../slice/modal/modal.slice';
import {
  PizzaIngredient,
  PizzaIngredientOption
} from '@/builders/pizza/dataTransformers/builderTypes';
import formattedPrice from '@/common/formattedPrice';
import useStyles from './ToppingsPicker.styles';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import {
  DETROIT_PIZZA,
  CRISPY_CUPPED_PEPPERONI,
  BIG_NEW_YORKER_DOUBLE_PEPPERONI,
  HOT_HONEY_PARTIAL,
  TAVERN_PIZZA
} from '@/builders/pizza/constants';
import constants from '../../../constants';

const DEFAULT_MINIMUM_DISPLAYED_ITEMS = 3;

interface ToppingsPickerProps {
  title: 'Veggies' | 'Meats';
  toppingOptions: PizzaIngredientOption[];
  selectedToppings: PizzaIngredient[];
  onToppingsUpdate: (value: PizzaIngredient[]) => void;
  isPizzaSizeSplittable: boolean;
  extraPrice?: number | null;
  reachedToppingLimit?: boolean;
  numDisplayedItems?: number;
  hideToppingsExtraPrice?: boolean;
  hideTitle?: boolean;
}

function shouldSeeAll(
  sortedToppingList: PizzaIngredientOption[],
  numDisplayedItems: number
): boolean {
  const selectedToppingsNotAtTheTop = sortedToppingList.filter(
    ({ selected }, index) => selected && index >= numDisplayedItems
  );
  return Boolean(selectedToppingsNotAtTheTop.length);
}

function getToppingsList(
  toppings: PizzaIngredientOption[]
): PizzaIngredientOption[] {
  return sortBy(
    toppings,
    (topping: PizzaIngredientOption) => topping.priority || '',
    (topping: PizzaIngredientOption) => stripCharacters(topping.name)?.toLowerCase() || ''
  );
}

const isPanCrust = (crust: PizzaIngredient | null): boolean => !!crust?.isPanCrust;

const panCrustToppingMaxModalDetails = (
  ctaCallback: () => void,
  altCtaCallback: () => void
) => ({
  title: 'Too much of a good thing...',
  body: 'Stick with 5 or fewer toppings for the perfect Pan crust',
  altCta: {
    text: "Don't Add",
    callback: altCtaCallback,
    reverseButtonsOrder: true
  },
  cta: {
    text: 'Add anyway',
    callback: ctaCallback,
    reverseButtonsOrder: true
  },
  hideCloseIcon: true
});

export const renderToppingExtraPriceText = (extraPrice?: number | null, selectRecipeDefaultToppingsCount?: number) => {
  const price = formattedPrice(extraPrice);
  if (!extraPrice) {
    return 'Additional toppings are an extra charge';
  }

  if (selectRecipeDefaultToppingsCount) {
    return `Additional toppings are ${price} each`;
  }
  return `${price} each`;
};

const ToppingsPicker = ({
  title,
  toppingOptions,
  selectedToppings,
  onToppingsUpdate,
  isPizzaSizeSplittable,
  extraPrice,
  reachedToppingLimit,
  numDisplayedItems = DEFAULT_MINIMUM_DISPLAYED_ITEMS,
  hideToppingsExtraPrice = false,
  hideTitle = false
}: ToppingsPickerProps): JSX.Element => {
  const classes = useStyles();
  const sortedToppingList = getToppingsList(toppingOptions);
  const showAllToppings = shouldSeeAll(sortedToppingList, numDisplayedItems);
  const [expandSeeAll, setSeeAll] = useState<boolean>(showAllToppings);
  const [{ enabled: ccpHardcodingDisabled }] = useDecision('cb-tavern_national_launch');
  const [{ enabled: yumBNYWarningEnabled }] = useDecision('fr-web3462-yum_bny_topping_warning');

  const isReachedMaxAllowedToppings = useSelector(
    selectors.isReachedToppingsLimit
  );
  const isReachedMaxAllowedPanCrustToppings = useSelector(
    selectors.isReachedPanCrustToppingsLimit
  );
  const isReachedMaxAllowedBNYCrustToppings = useSelector(
    selectors.isReachedBNYCrustToppingsLimit
  );
  const isPanCrustToppingModalShown = useSelector(
    modalSelectors.isPanCrustToppingModalShown
  );
  const preventAddTopping = reachedToppingLimit || isReachedMaxAllowedToppings;

  const alertModalForPanCrust = isReachedMaxAllowedPanCrustToppings && !isPanCrustToppingModalShown;

  const selectedCrust = useSelector(selectors.selectPizzaCrust);
  const selectRecipeDefaultToppingsCount = useSelector(
    selectors.selectRecipeDefaultToppingsCount
  );
  const panCrust = isPanCrust(selectedCrust);
  const dispatch = useDispatch();
  const toppingElList = useRef<(HTMLDivElement | null)[]>([]);
  const { isLineup } = useLineup();

  useEffect(() => {
    if (expandSeeAll) {
      const firstHiddenTopping = toppingElList.current[numDisplayedItems];
      (firstHiddenTopping?.firstChild as HTMLDivElement | undefined)?.focus();
    }
  }, [expandSeeAll, numDisplayedItems]);

  const analytics = useAnalytics();
  const { pizza } = analytics.analyticsDataModel;

  const toggleSeeAll = () => {
    const toggledSeeAll = !expandSeeAll;
    setSeeAll(toggledSeeAll);

    if (toggledSeeAll) {
      analytics.push(() => onPizzaToppingSeeAllClick(pizza.name ?? '', title, 'See All', isLineup));
    }
  };

  const toppingsList = expandSeeAll
    ? sortedToppingList
    : sortedToppingList.slice(0, numDisplayedItems);

  const findSelectedToppingOptions = (toppingId: string | undefined) => selectedToppings?.filter((ingredient) => ingredient.id !== toppingId) || [];

  const findToppingPortion = (toppingId: string | undefined) => selectedToppings?.find(
    (ingredient: PizzaIngredient) => ingredient.id === toppingId
  )?.portion;

  const addToppingWithPortionAndPlacement = (
    selectedIngredientToppings: PizzaIngredient[],
    toppingToAdd: PizzaIngredientOption,
    { hasExtra, placement }: OnToppingItemChangeProps
  ) => {
    const portion = hasExtra ? Portion.EXTRA : Portion.REGULAR;
    onToppingsUpdate([
      ...selectedIngredientToppings,
      toIngredientWithPortionAndPlacement(toppingToAdd, portion, placement)
    ]);
  };

  const sendDataAnalytics = (
    isExtraPortion: boolean,
    toppingName: string | null | undefined
  ) => {
    const action = isExtraPortion
      ? ToppingsExtraAnalytics.EXTRA
      : ToppingsSelectionAnalytics.ADD;
    analytics.push(() => onPizzaToppingClick(pizza.name ?? '', toppingName, action, isLineup));
  };

  const onPreventToAdd = (toppingCount: number) => {
    dispatch(
      openModal({
        title: 'Topping overload!',
        body: `For an evenly cooked pizza, our chef allows up to ${toppingCount} ingredients`,
        cta: {
          text: 'GOT IT'
        }
      })
    );
  };

  const openPanCrustToppingsWarningModal = (
    selectedToppingOptions: PizzaIngredient[],
    toppingToAdd: PizzaIngredientOption,
    toppingChangedProp: OnToppingItemChangeProps
  ) => {
    const ctaCallback = () => {
      addToppingWithPortionAndPlacement(
        selectedToppingOptions,
        toppingToAdd,
        toppingChangedProp
      );
      sendDataAnalytics(toppingChangedProp.hasExtra, toppingToAdd.name);
      dispatch(updatePanCrustToppingModalShown(true));
    };

    const altCtaCallback = () => {
      dispatch(updatePanCrustToppingModalShown(true));
    };

    dispatch(
      openModal(panCrustToppingMaxModalDetails(ctaCallback, altCtaCallback))
    );
  };

  const veggies = useSelector(selectors.selectPizzaVeggieToppings);
  const meats = useSelector(selectors.selectPizzaMeatToppings);
  const showBNYToppingModal = (toppingId: string, isExtra: boolean) => {
    const isExistingToppings = meats?.find((meat) => meat.id === toppingId)
      || veggies?.find((veggie) => veggie.id === toppingId);
    if (isReachedMaxAllowedBNYCrustToppings) {
      if (isExistingToppings) {
        return isExtra;
      }
      return true;
    }
    return false;
  };
  const isBNY = selectedCrust?.id?.includes(constants.BNY_CRUST_ID) || (yumBNYWarningEnabled && selectedCrust?.id?.includes(constants.YUM_BNY_CRUST_ID));

  const handleToppingChange = (
    topping: PizzaIngredientOption,
    toppingChangedProp: OnToppingItemChangeProps
  ) => {
    const portion = findToppingPortion(topping.id);
    const selectedToppingOptions = findSelectedToppingOptions(topping.id);
    const { hasExtra, isSelected, placement } = toppingChangedProp;
    const canAddPanCrustTopping = panCrust && alertModalForPanCrust;
    const showBNYCrustToppingModal = isBNY && showBNYToppingModal(topping.id, hasExtra);
    const isRegularPortion = !portion || (portion === Portion.REGULAR && hasExtra);
    const unselectExtra = portion === Portion.EXTRA && !hasExtra;
    const unselectTopping = !isSelected;

    switch (true) {
      case unselectTopping: {
        onToppingsUpdate(selectedToppingOptions);
        return;
      }
      case unselectExtra: {
        onToppingsUpdate([
          ...selectedToppingOptions,
          toIngredientWithPortionAndPlacement(
            topping,
            Portion.REGULAR,
            placement
          )
        ]);
        return;
      }
      case preventAddTopping && isRegularPortion: {
        onPreventToAdd(constants.MAX_TOPPINGS_ALLOWED);
        return;
      }
      case canAddPanCrustTopping: {
        openPanCrustToppingsWarningModal(
          selectedToppingOptions,
          topping,
          toppingChangedProp
        );
        return;
      }
      case showBNYCrustToppingModal: {
        onPreventToAdd(constants.MAX_TOPPINGS_ALLOWED_FOR_BNY_CRUST);
        return;
      }
      default: {
        addToppingWithPortionAndPlacement(
          selectedToppingOptions,
          topping,
          toppingChangedProp
        );
      }
    }
  };

  // Hide Crispy Cupped Peppers if not Detroit-Style, Big New Yorker, Hot Honey or Tavern
  const currentCrust = useSelector(selectors.selectPizzaCrustOption);
  const currentPizzaRecipe = useSelector(selectors.selectPizzaOptions);
  const isDetroitPizza = currentCrust?.name === DETROIT_PIZZA;
  const isTavernPizza = currentCrust?.name === TAVERN_PIZZA;
  const isBigNewYorkerDealsDP = currentPizzaRecipe?.summary?.displayName
    === BIG_NEW_YORKER_DOUBLE_PEPPERONI;
  const isBigNewYorkerDP = currentPizzaRecipe?.summary?.name === BIG_NEW_YORKER_DOUBLE_PEPPERONI;
  const isHotHoney = currentPizzaRecipe?.summary?.displayName?.includes(HOT_HONEY_PARTIAL)
    || currentPizzaRecipe?.summary?.name?.includes(HOT_HONEY_PARTIAL);
  const shouldShowCrispyCuppedPepperoni = ccpHardcodingDisabled
    || isDetroitPizza
    || isBigNewYorkerDealsDP
    || isBigNewYorkerDP
    || isHotHoney
    || isTavernPizza;
  const filteredToppingsList = useMemo(() => (shouldShowCrispyCuppedPepperoni && title === 'Meats'
    ? toppingsList
    : toppingsList.filter(
      (topping) => topping?.name !== CRISPY_CUPPED_PEPPERONI
    )), [shouldShowCrispyCuppedPepperoni, title, toppingsList]);
  // Putting this logic behind cb-tavern_national_launch to enable Crispy Cupped Peppers for every pizza when flag is on
  // Hide Crispy Cupped Peppers if not Detroit-Style, Big New Yorker, Hot Honey or Tavern ^^^

  return (
    <Grid
      item
      className={classes.root}
      data-testid={`${title.toLowerCase()}-toppings-picker`}
    >
      <div className={(!hideTitle || !hideToppingsExtraPrice) ? classes.header : ''}>
        {!hideTitle && <Typography variant="h3">{title}</Typography>}
        {!hideToppingsExtraPrice && (
          <Typography className={classes.priceDescription}>
            {renderToppingExtraPriceText(extraPrice, selectRecipeDefaultToppingsCount)}
          </Typography>
        )}
      </div>
      {filteredToppingsList.map((topping, index) => (
        <div
          key={`${index}-${topping.name}`}
          className={classes.picker}
          data-testid="toppings-list-item"
          ref={(element) => {
            toppingElList.current[index] = element;
          }}
        >
          <ToppingItemOptimized
            reachedToppingLimit={preventAddTopping}
            reachedPanCrustLimit={alertModalForPanCrust}
            reachedBNYCrustLimit={isBNY && isReachedMaxAllowedBNYCrustToppings}
            topping={topping}
            selectedIngredient={selectedToppings?.find(
              (selectedTopping) => selectedTopping.id === topping.id
            )}
            isPizzaSizeSplittable={isPizzaSizeSplittable}
            extraPrice={extraPrice}
            onChange={handleToppingChange}
          />
        </div>
      ))}
      {sortedToppingList.length > numDisplayedItems && (
        <Grid container justifyContent="center">
          <SeeAllButton
            expanded={expandSeeAll}
            onClick={toggleSeeAll}
            accessibleGroupName={title}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ToppingsPicker;
