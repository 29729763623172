import { useSelector } from 'react-redux';
import { isEmpty } from '@/utils';
import { selectors } from '../../slice/pizza.slice';
import restrictModalDetails from './restrictModalDetails';
import BuilderSteps from '../../BuilderSteps';
import SelectedBy from '@/common/SelectedBy';
import { PizzaIngredient, RuleItem } from '../../dataTransformers/builderTypes';

interface RestrictRuleModalProps {
  sizeId: string | undefined;
  crustId: string | undefined;
  ctaCallback: (type: BuilderSteps) => void;
  altCtaCallback?: () => void;
}

type RestrictRulesForSizeAndCrust = (
  sizeId: string | undefined,
  crustId: string | undefined
) => RuleItem[] | undefined;

export type ModalDetails = Partial<ModalContent> & { builderType: BuilderSteps };

const selectRestrictedItems = (rules: RuleItem[]) => {
  const filteredIds = rules
    .filter((x) => x.id)
    .map((x) => x.id as string);

  return (option?: PizzaIngredient) => {
    if (!option?.id) return [] as string[];
    const target = option.id;
    return filteredIds
      .filter((rule) => target.includes(rule) && option.name)
      .map(() => option.name as string);
  };
};

const getModalContent = (
  modalDetail: ModalDetails | null,
  isSystemSelected: boolean,
  { ctaCallback, altCtaCallback }: Partial<RestrictRuleModalProps>
): ModalContent | undefined => {
  if (!modalDetail) return undefined;

  const common = {
    title: modalDetail.title,
    body: modalDetail.body,
    bodyList: modalDetail.bodyList
  };

  if (isSystemSelected) {
    return {
      ...common,
      hideCloseIcon: true,
      cta: {
        text: 'ok',
        reverseButtonsOrder: true,
        callback: () => ctaCallback && ctaCallback(modalDetail.builderType)
      }
    };
  }

  return {
    ...common,
    extraContent: modalDetail.extraContent,
    cta: {
      text: 'Yes, continue',
      reverseButtonsOrder: true,
      callback: () => ctaCallback && ctaCallback(modalDetail.builderType)
    },
    altCta: {
      text: 'No, cancel change',
      reverseButtonsOrder: true,
      callback: altCtaCallback ? () => altCtaCallback() : undefined
    }
  };
};

const getRestrictedItems = (
  rules: RuleItem[],
  sauce: PizzaIngredient,
  finisher: PizzaIngredient | null,
  meatToppings: PizzaIngredient[],
  veggieToppings: PizzaIngredient[]
) => {
  const isItemRestricted = selectRestrictedItems(rules);

  // get restricted based on crust rules
  const restrictedSauce = isItemRestricted(sauce);
  const restrictedFlavor = isItemRestricted(finisher ?? undefined);
  const restrictedToppings = meatToppings.concat(veggieToppings).flatMap(isItemRestricted);
  const multipleIngredients = [...restrictedSauce, ...restrictedFlavor, ...restrictedToppings];

  // check if item is restricted
  const hasRestricted = {
    sauce: !isEmpty(restrictedSauce),
    flavor: !isEmpty(restrictedFlavor),
    topping: !isEmpty(restrictedToppings)
  };

  return {
    ingredients: multipleIngredients,
    hasRestricted: {
      sauce: hasRestricted.sauce,
      flavor: hasRestricted.flavor,
      topping: {
        single: hasRestricted.topping,
        multiple: restrictedToppings?.length > 1
      },
      multiple: Object.values(hasRestricted).filter(Boolean).length > 1
    }
  };
};

const getModalDetails = (
  findRestrictRulesForSizeAndCrust: RestrictRulesForSizeAndCrust,
  sauce: PizzaIngredient,
  finisher: PizzaIngredient | null,
  meatToppings: PizzaIngredient[],
  veggieToppings: PizzaIngredient[]
) => ({ sizeId, crustId, ...callbacks }: RestrictRuleModalProps) => {
  const isSystemSelected = sauce.selectedBy === SelectedBy.SYSTEM;

  const rules = findRestrictRulesForSizeAndCrust(sizeId, crustId) ?? [];
  const restrictedItems = getRestrictedItems(rules, sauce, finisher, meatToppings, veggieToppings);
  const modalDetail = restrictModalDetails({
    ...restrictedItems,
    isSystemSelected
  });

  return getModalContent(modalDetail, isSystemSelected, callbacks);
};

const useRestrictRuleModal = (): (props: RestrictRuleModalProps) => ModalContent | undefined => {
  const findRestrictRulesForSizeAndCrust = useSelector(selectors.selectRestrictRules);
  const selectedSauce = useSelector(selectors.selectPizzaSauce);
  const selectedCrustFlavor = useSelector(selectors.selectPizzaFinisher);
  const selectedMeatToppings = useSelector(selectors.selectPizzaMeatToppings);
  const selectedVeggieToppings = useSelector(selectors.selectPizzaVeggieToppings);

  return getModalDetails(
    findRestrictRulesForSizeAndCrust,
    selectedSauce,
    selectedCrustFlavor,
    selectedMeatToppings,
    selectedVeggieToppings
  );
};

export default useRestrictRuleModal;
