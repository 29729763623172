import {
  Checkbox, makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import colors from '../../../../common/colors';
import fontStyles from '../../../../common/fontStyles';
import LinkCaret, { CaretDirection } from '../../../../coreComponents/links/LinkCaret';

const useStyles = makeStyles(() => ({
  expandButton: {
    ...fontStyles.textLink,
    paddingRight: '8px'
  },
  add: {
    ...fontStyles.headerMainText,
    border: `1px solid ${colors.gray900}`,
    borderRadius: '2px',
    margin: '3px'
  },
  checkbox: {
    ...fontStyles.headerMainText,
    padding: '12px 8px 12px 12px',
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export const SeeAllButton = (
  {
    expanded,
    onClick,
    accessibleGroupName
  }: { expanded: boolean; onClick: () => void; accessibleGroupName: string }
): JSX.Element => {
  const text = expanded ? 'See less' : 'See all';
  const expandedTestId = expanded ? 'see-less' : 'see-more';
  const caretDirection = expanded ? CaretDirection.UP : CaretDirection.DOWN;

  return (
    <LinkCaret
      tabIndex={0}
      text={text}
      caretDirection={caretDirection}
      onClick={onClick}
      ariaLabel={`See all ${accessibleGroupName.slice(0, -1)} toppings`}
      ariaExpanded={expanded}
      dataTestId={`${accessibleGroupName.toLowerCase()}-${expandedTestId}`}
    />
  );
};
interface EditButtonProps {
  expanded: boolean;
  onClick?: () => void;
  ariaHidden?: boolean;
}

export const EditButton = ({
  expanded,
  onClick,
  ariaHidden
}: EditButtonProps): JSX.Element => {
  const caretDirection = expanded ? CaretDirection.UP : CaretDirection.DOWN;
  return (
    <LinkCaret
      tabIndex={-1}
      text="Edit"
      onClick={onClick}
      caretDirection={caretDirection}
      ariaHidden={ariaHidden}
      dataTestId="collapse-edit-button"
    />
  );
};

export const AddButton = ({
  expanded,
  testId,
  testIdLabel
}: {
  expanded: boolean;
  testId?: string;
  testIdLabel: string | undefined;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Checkbox
      data-testid={expanded ? `modifier-${testIdLabel}-check` : undefined}
      tabIndex={-1}
      disableRipple
      disableTouchRipple
      checked={expanded}
      icon={<AddIcon data-testid={`${testId}-add-icon`} className={classes.add} />}
      className={classes.checkbox}
    />
  );
};
