import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCaloriesByPlacementAndPortionText } from '../../../../hooks/pizzaIngredientOptionsSelectors';
import Placement from '../../../../../../common/Placement';
import Portion from '../../../../../../common/Portion';
import ToppingItem from '../ToppingItem';
import {
  PlacementChoice,
  PizzaIngredient,
  PizzaIngredientOption
} from '@/builders/pizza/dataTransformers/builderTypes';
import { RootState } from '@/rootStateTypes';

export interface OnToppingItemChangeProps {
  hasExtra: boolean;
  placement: PlacementChoice;
  isSelected: boolean;
}

interface ToppingItemOptimizedProps {
  topping: PizzaIngredientOption;
  selectedIngredient: PizzaIngredient | undefined;
  isPizzaSizeSplittable: boolean;
  extraPrice: number | undefined | null;
  onChange: (
    topping: PizzaIngredientOption,
    onToppingItemChange: OnToppingItemChangeProps
  ) => void;
  reachedToppingLimit?: boolean;
  reachedPanCrustLimit?: boolean;
  reachedBNYCrustLimit?: boolean;
}

const ToppingItemOptimized = ({
  topping,
  selectedIngredient,
  isPizzaSizeSplittable,
  extraPrice,
  onChange,
  reachedToppingLimit,
  reachedPanCrustLimit,
  reachedBNYCrustLimit
}: ToppingItemOptimizedProps): JSX.Element => {
  const {
    splittable, name, outOfStock, id, image, maxAllowed, weights
  } = topping;
  const nutrition = useCaloriesByPlacementAndPortionText(
    topping,
    selectedIngredient
  );

  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );

  const yumToppingExtraAllowed = !!(weights?.length && weights.find((weight) => weight.includes('EXTRA'))) || false;

  const itemProps = {
    id,
    name,
    imgUrl: image,
    isOutOfStock: outOfStock,
    isSelected: selectedIngredient !== undefined,
    placementSelected: selectedIngredient?.placement ?? Placement.WHOLE,
    placementAvailable: isYumEcomm ? splittable : splittable && isPizzaSizeSplittable,
    extraAvailable: (maxAllowed && maxAllowed > 1) || yumToppingExtraAllowed,
    extraSelected: selectedIngredient?.portion === Portion.EXTRA,
    extraPrice,
    nutrition,
    reachedToppingLimit,
    reachedPanCrustLimit,
    reachedBNYCrustLimit
  };

  const onChangeCallback = useCallback((event) => onChange(topping, event), [
    onChange,
    topping
  ]);

  return (<ToppingItem {...itemProps} onChange={onChangeCallback} />);
};

export default ToppingItemOptimized;
