import { useMemo } from 'react';
import { useRouter } from 'next/router';
import Routes from '@/router/routes';

export const useLineup = () => {
  const router = useRouter();
  const redirectTo = router?.query?.redirectTo;
  const isLineup = useMemo(() => redirectTo === Routes.LINEUP, [redirectTo]);

  return {
    isLineup
  };
};
