import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from '../../../common/colors';
import {
  BuilderSection,
  BuilderSectionAction
} from '../../../dataAnalytics/analyticsTypes';
import { onPizzaBuilderSectionClick } from '../../../dataAnalytics/dataAnalyticsHelper';
import { formatTestId } from '../../../common/string-formatter';
import telemetry from '../../../telemetry';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const useStyles = makeStyles(() => ({
  accordion: {
    borderTop: `1px solid ${colors.gray400}`,
    boxShadow: 'none',
    '&.MuiAccordion-root:last-child': {
      borderBottom: `1px solid ${colors.gray400}`
    }
  },
  accordionRoot: {
    '&.Mui-expanded': {
      margin: '0px'
    },
    '&.MuiAccordion-root:before': {
      display: 'none'
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 4px'
    }
  },
  accordionSummary: {
    padding: '1em 0 1em 0',
    marginBottom: '0.5em',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: '12px'
    }
  },
  accordionDetails: {
    marginBottom: '34px'
  },
  expandIcon: {
    color: colors.gray900,
    fontSize: '2.9rem'
  }
}));

const PizzaStepAccordionData = {
  [BuilderSection.CRUST]: {
    heading: 'Crust',
    analyticsAction: BuilderSection.CRUST
  },
  [BuilderSection.TOPPINGS]: {
    heading: 'Toppings',
    analyticsAction: BuilderSection.TOPPINGS
  },
  [BuilderSection.SCCF]: {
    heading: 'Sauce & cheese',
    analyticsAction: BuilderSection.SCCF
  }
};

export type PartialBuilderSectionAction = Exclude<BuilderSectionAction, 'Special Instructions'>;

interface PizzaStepAccordionProps {
  children: JSX.Element;
  expanded: boolean;
  onChange?: (event: any) => void;
  onExpanded?: () => void;
  type: PartialBuilderSectionAction;
  subHeading: string;
  subHeadingAlert?: boolean;
  subHeadingTestId?: string;
  hideExpandIcon?: boolean;
}

const PizzaStepAccordion = (props: PizzaStepAccordionProps): JSX.Element => {
  const {
    children,
    expanded,
    onChange,
    type,
    subHeading,
    subHeadingAlert,
    onExpanded,
    subHeadingTestId,
    hideExpandIcon
  } = props;

  const classes = useStyles();
  const accordionTestId = formatTestId(PizzaStepAccordionData[type].heading);

  // Generate an id that's valid for accessibility
  const ariaId = Buffer.from(PizzaStepAccordionData[type].heading).toString('base64');

  const analytics = useAnalytics();
  const {
    deal: {
      deal_name: dealName = '',
      deal_id: dealId = ''
    },
    pizza
  } = analytics.analyticsDataModel;
  const { isLineup } = useLineup();

  const addAnalyticsExpand = (
    action: BuilderSectionAction,
    isExpanded: boolean
  ) => {
    analytics.push(() => onPizzaBuilderSectionClick(
      pizza.name ?? '',
      dealId,
      dealName,
      action,
      isExpanded,
      isLineup
    ));
  };

  const handleOnChange = (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) => {
    onChange?.(event);

    addAnalyticsExpand(PizzaStepAccordionData[type].analyticsAction, isExpanded);
    telemetry.addCustomEvent(`pizza-builder-${accordionTestId}-accordion-click`);
  };

  return (
    <Accordion
      expanded={expanded}
      aria-expanded={expanded}
      onChange={!hideExpandIcon ? handleOnChange : undefined}
      className={classes.accordion}
      classes={{
        root: classes.accordionRoot
      }}
      square
      TransitionProps={{
        onEntered: onExpanded
      }}
    >
      <AccordionSummary
        expandIcon={!hideExpandIcon && <ExpandMoreIcon className={classes.expandIcon} />}
        aria-controls={`${ariaId}-content`}
        id={`${ariaId}-header`}
        className={classes.accordionSummary}
        data-testid={`${accordionTestId}-${expanded ? 'close' : 'open'}`}
      >
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item md zeroMinWidth>
            <Typography variant="h2">
              {PizzaStepAccordionData[type].heading}
            </Typography>

            <Typography color={subHeadingAlert ? 'error' : undefined} data-testid={subHeadingTestId}>
              {subHeading}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default PizzaStepAccordion;
