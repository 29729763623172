import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Placement as CCPlacement } from '@pizza-hut-us-development/client-core';
import colors from '../../../../../common/colors';
import { extraSmallMobileStartBreakpoint } from '../../../../../materialUi/theme';
import ToppingPlacement from './ToppingPlacement';
import ExtraTopping from './ExtraTopping';
import { PlacementChoice } from '@/builders/pizza/dataTransformers/builderTypes';

interface ToppingPreferenceProps {
  label: string | undefined;
  showPlacement: boolean | undefined;
  showExtraToggle: boolean;
  toggleExtra: boolean;
  selectedPlacement: PlacementChoice | CCPlacement;
  handleToggleExtra: () => void;
  handlePlacementChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  extraPrice: number | undefined | null;
}

const useStyles = () => makeStyles((theme: Theme) => ({
  root: {
    height: '48px',
    backgroundColor: colors.white,
    borderTop: `solid 1px ${colors.gray400}`
  },
  dividerRoot: {
    padding: '1px 2px'
  },
  divider: {
    height: '26px',
    margin: '8px 16px 8px 3px',
    [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
      margin: '8px 8px 8px 0px'
    }
  }
}))();

const ToppingPreference = (props: ToppingPreferenceProps): JSX.Element => {
  const classes = useStyles();
  const {
    label,
    showPlacement,
    showExtraToggle,
    toggleExtra,
    selectedPlacement,
    handleToggleExtra,
    handlePlacementChange,
    extraPrice
  } = props;

  return (
    <Grid container className={classes.root} direction="row" data-testid="topping-preferences">
      {showPlacement && (
        <>
          <Grid item>
            <ToppingPlacement
              label={label}
              value={selectedPlacement}
              onChange={handlePlacementChange}
            />
          </Grid>
          <Grid item className={classes.dividerRoot}>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
        </>
      )}
      <Grid item>
        {showExtraToggle
          && (
          <ExtraTopping
            label={label}
            checked={toggleExtra}
            onToggle={handleToggleExtra}
            extraPrice={extraPrice}
          />
          )}
      </Grid>
    </Grid>
  );
};

export default ToppingPreference;
