enum BuilderSteps {
  MAIN = 'MAIN',
  SIZE = 'SIZE',
  CRUST = 'CRUST',
  SAUCE = 'SAUCE',
  CHEESE = 'CHEESE',
  FLAVOR = 'FLAVOR',
  TOPPINGS = 'TOPPINGS',
  SPECIAL_INSTRUCTIONS = 'SPECIAL INSTRUCTIONS'
}

export default BuilderSteps;
