import { makeStyles } from '@material-ui/core/styles';
import fontStyles from '@/common/fontStyles';
import colors from '@/common/colors';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '4px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  priceDescription: {
    ...fontStyles.bodySmall,
    marginLeft: '10px',
    color: colors.gray600
  },
  picker: {
    marginBottom: '16px'
  }
}));

export default useStyles;
