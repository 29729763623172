import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, Radio as RadioMaterialUI } from '@material-ui/core';
import colors from '../../../common/colors';

interface RadioProps {
  name: string,
  value: string | null | undefined,
  checked?: boolean,
  disabled?:boolean,
  outOfStock?: boolean
}

const useStyles = makeStyles(() => ({
  radio: {
    width: '20px',
    height: '20px',
    margin: '8px 8px 17px 2px',
    alignSelf: 'start',
    color: colors.red
  },
  unCheckedIcon: ({ outOfStock }: any) => ({
    border: `solid 1px ${outOfStock ? colors.gray400 : colors.gray900}`,
    padding: '8px',
    borderRadius: '50%',
    backgroundColor: outOfStock && `${colors.gray100}`
  })
}));

const Radio = (props:RadioProps) => {
  const {
    name, checked, value, outOfStock
  } = props;
  const classes = useStyles({ outOfStock });

  return (
    <RadioMaterialUI
      data-testid={`${name}-radiobutton`}
      checked={checked}
      value={value}
      icon={<span className={classes.unCheckedIcon} />}
      inputProps={{ 'aria-label': `${name}`, title: `${name}-radiobutton` }}
      classes={{
        root: classes.radio
      }}
      checkedIcon={<CheckCircleIcon />}
    />
  );
};

export default Radio;
