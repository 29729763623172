import React from 'react';
import { makeStyles } from '@material-ui/core';
import colors from '../colors';
import { largeStartBreakpoint } from '@/materialUi/theme';
import { zIndex } from '@/localization/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '72px',
    bottom: '0px',
    position: 'sticky',
    margin: '97px 0 0',
    padding: '16px 0',
    boxShadow: '0px 4px 0px 0px rgb(255, 255, 255), 0px -2px 4px 0px rgba(0, 0, 0, 0.3)',
    backgroundColor: colors.white,
    boxSizing: 'border-box',
    zIndex: zIndex.LOWER_THAN_GOOGLE_AUTO_COMPLETE,
    [theme.breakpoints.down(largeStartBreakpoint)]: {
      margin: '57px 0 0'
    },
  }
}));

interface AddToCartFooterProps {
  children: JSX.Element;
}

const AddToCartFooter = ({ children }: AddToCartFooterProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      role="banner"
    >
      {children}
    </div>
  );
};

export default AddToCartFooter;
