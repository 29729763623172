import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, CircularProgress, Grid, makeStyles
} from '@material-ui/core';
import { useRouter } from 'next/router';
import telemetry from '../../../telemetry';
import useWindowSize, { isMobile } from '../../../common/useWindowSize';
import { openModal } from '@/localization/actions';
import RecipeQuantitySelector from '../../../coreComponents/buttons/RecipeQuantitySelector';
import fontStyles from '../../../common/fontStyles';
import colors from '../../../common/colors';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import { onAddToOrderButtonSuccess } from '@/dataAnalytics/dataAnalyticsHelper';
import useAddToCart from '../../../cart/hooks/useAddToCart';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { Deal } from '@/builders/deals/slice/dealTypes';
import {
  cartErrorModalDetails,
  getDealErrorModalDetails,
  NOT_COMBINABLE_DEALS_TITLE
} from '@/common/Modal/modalDetails';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';
import { getGlobalId } from '@/cart/helpers/getGlobalId';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';

export interface AddToCartButtonProps {
  item: BuiltPizza | Deal;
  onFinishedAdd: () => void;
  showQuantity: boolean;
  buttonText: string;
  onClickAnalytics?: () => void;
  onSuccessAddToCartAnalytics?: () => void;
  quantity?: number;
  isEditFlow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  addBtn: {
    ...fontStyles.primaryCtaLabel,
    'text-transform': 'uppercase',
    height: '32px',
    width: '88px',
    padding: '6px 0 7px 0',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    border: `solid 1px ${colors.red}`,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '226px',
      height: '40px'
    }
  },
  hasNoQuantity: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    width: '226px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    },
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '226px'
    }
  }
}));

const AddToCartButton = (props: AddToCartButtonProps): JSX.Element => {
  const {
    item, onFinishedAdd, showQuantity, buttonText, onClickAnalytics, onSuccessAddToCartAnalytics, quantity = 1, isEditFlow = false
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const { addToCart } = useAddToCart();
  const [loading, setLoading] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const { isLineup } = useLineup();
  const analytics = useAnalytics();
  const dealData = useSelector(dealSelectors.selectDeal);
  const globalDealId = getGlobalId(dealData?.data?.id);
  const optimizelyInstance = getOrInitializeOptimizely();

  const onAddToCartFinishedSuccessfully = () => {
    if (onSuccessAddToCartAnalytics) {
      onSuccessAddToCartAnalytics();
    } else {
      analytics.push(() => onAddToOrderButtonSuccess(item, isLineup));
    }

    optimizelyInstance?.track('AddToOrder', { dealId: globalDealId as string });

    // Prevent routing if we're in the redemption flow
    if (!router.query.red?.toString()) {
      onFinishedAdd();
    }

    setLoading(false);
  };

  const onFailedAddToOrder = (err?: Error) => {
    let dealErrorModalDetails;

    if (err && err.message) {
      dealErrorModalDetails = getDealErrorModalDetails(NOT_COMBINABLE_DEALS_TITLE, err.message);
    }
    dispatch(openModal(dealErrorModalDetails || cartErrorModalDetails));
    setLoading(false);
  };

  const addToOrderAction = (eventKeyboardClick: boolean) => {
    setLoading(true);
    addToCart(
      item,
      onAddToCartFinishedSuccessfully,
      onFailedAddToOrder,
      undefined,
      eventKeyboardClick,
      isLineup
    );
  };

  const handleClick = (eventProps: React.MouseEvent<Element>) => {
    if (!loading) {
      const telemetryWindowAction = isMobile(windowSize) ? 'mobile-' : '';
      telemetry.addCustomEvent(`${telemetryWindowAction}pizza-builder-add-to-cart-button-click`);
      onClickAnalytics?.();

      addToOrderAction(eventProps.detail === 0);
    }
  };

  return (
    <Grid item container wrap="nowrap" justifyContent="flex-end">
      {showQuantity && <RecipeQuantitySelector quantity={quantity} />}
      <Button
        data-testid="add-to-cart-button"
        className={`${classes.addBtn} ${showQuantity ? '' : classes.hasNoQuantity}`}
        onClick={handleClick}
        variant="contained"
        color="primary"
        aria-label={buttonText}
      >
        {loading ? <CircularProgress size={24} /> : buttonText}
      </Button>
    </Grid>
  );
};

export default AddToCartButton;
